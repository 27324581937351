const fullUrl = window.location.hostname;
const port = window.location.port;
const mainUrl = fullUrl + ":" + port;
const url = `https://${mainUrl}/api`;
const urls = {
  loginUrl: `${url}/user/login/`,
  resetPass: `${url}/user/resetpasswordisfirst/`,
  secResetPass: `${url}/user/resetpassword/`,
  checkFgPassUrl: `${url}/user/checkuser/`,
  fgPassUrl: `${url}/user/forgot_password/`,
  checkToken: `${url}/user/isauth/`,
  adminReport: `${url}/admin-panel/gozareshadminuser/`,
  clientInfo: `${url}/accounting/userinfo`,
  registredByAdmin: `${url}/admin-panel/registeruserbyadmin`,
  employee: `${url}/admin-panel/registeremployeebyadmin`,
  havale: `${url}/accounting/assigntahesab/`,
  tradeSearch: `${url}/admin-panel/tradesearch/`,
  rejectHavale: `${url}/accounting/rejectassignment/`,
  userPhone: `${url}/user/phone_name/`,
  senderReport: `${url}/user/sendergozaresh/`,
  reciverReport: `${url}/user/recivergozaresh/`,
  searchHavale: `${url}/admin-panel/search/`,
  userHavale: `${url}/accounting/assingment/`,
  active: `${url}/admin-panel/activeuser`,
  inActive: `${url}/admin-panel/inactiveuser`,
  duClient: `${url}/admin-panel/uptadeuserbyadmin`,
  duTrader: `${url}/admin-panel/updateemployeebyadmin`,
  notif: `${url}/admin-panel/ersalmoshakhasat`,
  searchSender: `${url}/admin-panel/searchsender`,
  searchReciver: `${url}/admin-panel/searchreciver`,
  searchAmount: `${url}/admin-panel/searchweight`,
  customerSearch: `${url}/accounting/userpretahesabsearch/`,
  nameSearch: `${url}/accounting/userpretahesabnamesearch/`,
  updateCostumer:`${url}/admin-panel/editusertahesab`
};
export default urls;
