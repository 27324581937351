import React, { useState, useEffect } from "react";
import { Toaster,toast } from "sonner";
import AdminReports from "../admin_components/adminReports/adminReports";
import UserReports from "../user_Components/userReports/userReports";
import urls from "../../url"
export default function () {
  const [role, setRole] = useState("");
  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("userRole"));
    if (localStorageData) {
      const roleOfUser = localStorageData.userRole;
      setRole(roleOfUser);
    }
  }, []);
  console.log("yo", role);
  const roleComponents = {
    admin: <AdminReports />,
    user: <UserReports />,
    admin_trade: <AdminReports />
  };
  return <div>
  <Toaster richColors position="top-center" dir="rtl" visibleToasts={1} closeButton />
  <div>{roleComponents[role] || <p>Loading...</p>}</div>
  </div>;
}