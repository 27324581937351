import React from "react";
import Lnav from "./user_home_navs/userLeftNav";
import Rnav from "./user_home_navs/userRightNav";
import "./userhome.css";

export default function UserHome() {
  return (
    <body class="body">
      <div class="container-fluid cHeight d-flex p-0">
        <div class="col-9 d-flex">
          <Rnav />
          {/* <div className="col-10 px-4 d-flex pt-3 flex-column">
            <div className="cBox d-flex p-1 rounded-3 align-items-center mx-auto mb-2">
              <span className="me-1">آبشده</span>
              <div className="cBorder rounded-3 d-flex align-items-center">
                <span className="Farsi-Digits">11:36:38</span>
                <span className="icon11 d-inline-block"></span>
              </div>
            </div>

            <div className="price text-black rounded-3 pb-1">
              <div className="homeWrapper d-flex fw-bold py-1">
                <span className="col-4 text-end pe-3">کالا</span>
                <span className="col-4 text-center">مظنه خرید</span>
                <span className="col-4 text-start cPE">مظنه فروش</span>
              </div>

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center">
                    <p className="m-0">آبشده نقد فردا</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      10,545,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      10,535,000
                    </p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center">
                    <p className="m-0">آبشده نقد پس فردا</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">-</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      10,544,000
                    </p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center">
                    <p className="m-0">آبشده نقد پس فردا</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      10,558,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">-</p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center">
                    <p className="m-0">آبشده نقد پس فردا</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      10,558,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">-</p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center">
                    <p className="m-0">آبشده نقد پس فردا</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      10,558,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">-</p>
                  </span>
                </div>
              </div>
            </div>
            <span className="icon12 mx-auto mt-1 mb-1"></span>

            <div className="cBox d-flex p-1 rounded-3 align-items-center mx-auto mb-2">
              <span className="me-1">مسکوکات</span>
              <div className="cBorder rounded-3 d-flex align-items-center">
                <span className="Farsi-Digits">11:36:38</span>
                <span className="icon11 d-inline-block"></span>
              </div>
            </div>

            <div className="price text-black rounded-3 pb-1">
              <div className="homeWrapper d-flex fw-bold py-1">
                <span className="col-4 text-end pe-3">کالا</span>
                <span className="col-4 text-center">مظنه خرید</span>
                <span className="col-4 text-start cPE">مظنه فروش</span>
              </div>

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center Farsi-Digits">
                    <p className="m-0">سکه 86</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      28,800,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      28,730,000
                    </p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center Farsi-Digits">
                    <p className="m-0">سکه تاریخ پایین</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      25,395,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      25,275,000
                    </p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center Farsi-Digits">
                    <p className="m-0">سکه 86</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      28,800,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      28,730,000
                    </p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center Farsi-Digits">
                    <p className="m-0">سکه تاریخ پایین</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      25,395,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      25,275,000
                    </p>
                  </span>
                </div>
              </div>
              <hr className="cHr mx-3" />

              <div className="px-2 my-1">
                <div className="cRow bg-white rounded-3 d-flex justify-content-between">
                  <span className="col-4 text-start d-flex align-items-center Farsi-Digits">
                    <p className="m-0">سکه تاریخ پایین</p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-center align-items-center">
                    <p className="cSpan1 col-5 m-0 rounded-3 d-inline">
                      25,395,000
                    </p>
                  </span>
                  <span className="col-4 text-center Farsi-Digits d-flex justify-content-end align-items-center">
                    <p className="cSpan2 col-5 m-0 rounded-3 d-inline">
                      25,275,000
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <span className="icon12 mx-auto mt-1 mb-1"></span>
          </div> */}
        </div>
        {/* <Lnav /> */}
      </div>
    </body>
  );
}
