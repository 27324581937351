import React from "react";
import "./notfound.css";
export default function notFound() {
  const containerStyle = {
    maxWidth: "550px",
    height: "60vh",
    backgroundColor: "rgba(116, 116, 116, 0.850)",
    margin: "20vh auto",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: "#d9d9d9",
    padding: "15px 100px",
  };

  const wrapperStyle = {
    maxWidth: "550px",
    display: "flex",
    flexDirection: "column",
  };

  const cardStyle = {
    display: "flex",
    position: "relative",
    alignItems: "center"
  };

  const adadStyle = {
    fontSize: "105px",
    top: "50px",
    lineHeight: "100px",
  };

  const textStyle = {
    fontSize: "20px",
  };
  return (
    <div style={containerStyle}>
      <div style={wrapperStyle}>
        <div style={cardStyle}>
          <p style={adadStyle}>4</p>
          <span className="dolar"></span>
          <p style={adadStyle}>4</p>
        </div>
        <p className="user-select-none" style={textStyle}>صفحه مورد نظر پیدا نشد</p>
      </div>
    </div>
  );
}
