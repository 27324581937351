import React, { useCallback, useState} from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import AuthContext from "./context/authContext";
function App() {
  const router = useRoutes(routes);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const login = useCallback((phoneNumber, userRole, token) => {
    setToken(token);
    setIsLoggedIn(true);
    setUserRole(userRole);
    setPhoneNumber(phoneNumber);
    localStorage.setItem("user", JSON.stringify({ token }));
    localStorage.setItem("userRole", JSON.stringify({ userRole }));
    localStorage.setItem("phoneNumber", JSON.stringify({ phoneNumber }));
  }, []);
  return (
    <>
      <AuthContext.Provider
        value={{
          isLoggedIn,
          token,
          userRole,
          phoneNumber,
          login,
        }}
        className="app"
      >
        {router}
      </AuthContext.Provider>
    </>
  );
}
export default App;
