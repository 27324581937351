import React, { useState, useEffect } from "react";
import { Toaster, toast } from "sonner";
import { Navigate } from "react-router-dom";
import secFgPassYup from "./secFgPassYup";
import "./forgetpass.css";
import eyeOff from "../../asset/publicAssest/visibility_off.svg";
import eyeOn from "../../asset/publicAssest/visibility_on.svg";
import urls from "../../url";
const ForgetPass = () => {
  const randString = parseInt(Math.floor(10000 + Math.random() * 90000));
  const [showFirstPart, setShowFirstPart] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [showSecPart, setShowSecPart] = useState(false);
  const [goToLogin, setGoToLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [smsCode, setSmsCode] = useState(randString);
  const [capErrorMsg, setCapErrorMsg] = useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  useEffect(() => {
    let timer;
    if (timerRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setTimerRunning(false);
    }
    return () => clearInterval(timer);
  }, [timerRunning, timeLeft]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (phoneInput == "") {
      setPhoneErrorMsg("وارد کردن شماره موبایل اجباری است");
    }
    if (codeInput == "") {
      setCapErrorMsg("وارد کردن کد پیامکی اجباری است");
    } else if (parseInt(codeInput) !== smsCode) {
      setCapErrorMsg("کد پیامک شده درست نمی باشد");
    } else {
      setShowFirstPart(false);
      setShowSecPart(true);
      setCapErrorMsg("");
    }
  };
  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) || newValue === '') {
      setPhoneInput(newValue);
    } else {
      toast.error( "لطفاشماره تلفن را با ۰۹ شروع کرده و از اعداد استفاده کنید");
    }
  };
  const resetTimer = (e) => {
    e.preventDefault();
    if (phoneInput.length == 11) {
      fetchData();
    } else {
      toast.error("شماره تلفن را به درستی وارد کنید");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };
  const fetchData = async (e) => {
    const formData = new FormData();
    formData.append("phone_number", phoneInput.toString());

    try {
      const response = await fetch(`${urls.checkFgPassUrl}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        smsVal();
      } else {
        const statusCode = response.status;
        if (statusCode === 404) {
          throw new Error("کاربر یافت نشد");
        } else {
          throw new Error("مشکلی در تایید کردن شماره تلفن بوجود آمده");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const capChangeHandler = (e) => {
    const smsCode = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(smsCode)) {
      setCodeInput(smsCode);
    }
  };
  const smsVal = async () => {
    try {
      const response = await fetch(
        `https://api.kavenegar.com/v1/74663543567649323755774D644845683857672F76556262705663704D54424F665A2B4C6A617A614A6E633D/verify/lookup.json?receptor=${phoneInput}&token=${smsCode}&template=ramzyekbarmasraf`,
        {
          method: "POST",
        }
      );
      const result = await response.json();
      setTimeLeft(120);
      setTimerRunning(true);
      toast.success("کد با موفقیت ارسال شد");
    } catch (error) {
      toast.error("مشکلی در ارسال کد وجود دارد");
    }
  };
  const handlePassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setPassword(newWords);
    } else {
      setPassword(words);
      setPassErrorMsg("");
      setError({});
    }
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = (event) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleConfirmPassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setConfirmPassword(newWords);
    } else {
      setConfirmPassword(words);
      setConfirmPasswordErrorMsg("");
      setError({});
    }
  };
  const secSubmitHandler = async (event) => {
    event.preventDefault();
    const passYup = {
      nPass: event.target[0].value,
      cPass: event.target[3].value,
    };
    try {
      const isValidYUP = await secFgPassYup.validate(passYup, {
        abortEarly: false,
      });
      if (isValidYUP) {
        const letterRegex = /[a-z]/;
        const capitalRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const hasLetter = letterRegex.test(password);
        const hasCapital = capitalRegex.test(password);
        const hasNumber = numberRegex.test(password);

        if (!hasCapital || !hasNumber || !hasLetter) {
          setPassErrorMsg(
            "رمز عبور باید حداقل یک حرف بزرگ و کوچک و یک عدد داشته باشد."
          );
        } else if (password !== confirmPassword) {
          setConfirmPasswordErrorMsg("تکرار رمز جدید با رمز جدید یکسان نیست");
        } else {
          setLoading(true);
          sendNewInfosToApi();
        }
      }
    } catch (err) {
      let errors = err.inner
        ? err.inner.reduce(
            (acc, err) => ({
              ...acc,
              [err.path]: err.message,
            }),
            {}
          )
        : {};
      setError(errors);
    }
  };

  if (goToLogin) {
    return <Navigate to="/login" />;
  }
  const sendNewInfosToApi = async () => {
    const formData = new FormData();
    formData.append("new_password", password.toString());
    formData.append("phone_number", phoneInput.toString());
    formData.append("confirm_new_password", confirmPassword.toString());

    try {
      const response = await fetch(`${urls.fgPassUrl}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        toast.success("رمز شما با موفقیت تغییر پیدا کرد");
        setTimeout(() => {
          setGoToLogin(true);
        }, 700);
        setLoading(false);
      }
    } catch (error) {
      toast.error("مشکلی در تغییر رمز وجود دارد");
      setLoading(false);
    }
  };
  return (
    <body class="body">
      <Toaster
        richColors
        position="top-center"
        dir="rtl"
        visibleToasts={1}
        closeButton
      />
      <div class="container-fluid d-flex justify-content-center height100 align-items-center">
        <div class="row col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
          <div class="fgWrapper rounded-4 px-0 px-sm-4 py-4">
            <div class="row col-10" style={{ marginRight: "8.33%" }}>
              {showFirstPart && (
                <form class="d-flex flex-column p-0">
                  <h1 class="fs-4 fw-bold">بازیابی رمز عبور</h1>
                  <div>
                    <label for="pNumber" class="mb-1 mt-3">
                      شماره موبایل
                    </label>
                    <div class="d-flex">
                      <input
                        id="pNumber"
                        class="fgInputs rounded ps-2 Farsi-Digits pHolder w-100"
                        type="text"
                        dir="ltr"
                        autocomplete="off"
                        onChange={handlePhoneNumberChange}
                        maxlength="11"
                        placeholder="شماره موبایل خود را وارد کنید"
                      />
                    </div>
                    {phoneErrorMsg && (
                      <p className="more" style={{ color: "red" }}>
                        {phoneErrorMsg}
                      </p>
                    )}
                  </div>
                  <div>
                    <label for="code" class="mb-1 mt-3">
                      کد پیامکی
                    </label>
                    <div class="d-flex">
                      <div class="col-8 col-lg-9 ps-1">
                        <input
                          id="code"
                          class="fgInputs rounded ps-2 Farsi-Digits pHolder w-100"
                          type="text"
                          autocomplete="off"
                          value={codeInput}
                          maxlength="5"
                          onChange={capChangeHandler}
                          dir="ltr"
                          placeholder="کد پیامک شده را وارد کنید"
                        />
                      </div>
                      {timeLeft <= 0 ? (
                        <div class="col-4 col-lg-3">
                          <button
                            class="bColor rounded border-0 text-light fw-bold h-100 w-100"
                            onClick={resetTimer}
                          >
                            ارسال کد
                          </button>
                        </div>
                      ) : (
                        <div class="col-4 col-lg-3">
                          <button class="bColor rounded border-0 Farsi-Digits text-light fw-bold h-100 w-100" disabled>
                            {formatTime(timeLeft)}
                          </button>
                        </div>
                      )}
                    </div>
                    {capErrorMsg && (
                      <p className="more" style={{ color: "red" }}>
                        {capErrorMsg}
                      </p>
                    )}
                  </div>
                  <div class="d-felx text-center mt-4">
                    <button
                      class="editB1 editB2"
                      onClick={() => setGoToLogin(true)}
                    >
                      برگشت به صفحه‌ی ورود
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button
                      class="bColor rounded border-0 text-light fw-bold px-5 py-2 custom-mt"
                      type="submit"
                      onClick={submitHandler}
                    >
                      تایید
                    </button>
                  </div>
                </form>
              )}
              {showSecPart && (
                <form
                  onSubmit={secSubmitHandler}
                  class="d-flex flex-column p-0"
                >
                  <p className="more Farsi-Digits fontSize">
                    رمز عبور جدید باید بین 8 تا 12 کاراکتر باشد و از ترکیب
                    اعداد، حروف بزرگ و کوچک تشکیل شود
                  </p>
                  <div>
                    <label for="nP" class="mb-1 mt-4">
                      رمز عبور جدید
                    </label>
                    <div class="d-flex">
                      <div class="col-11">
                        <input
                          id="nP"
                          class="fgInputs rounded-end w-100 pHolder"
                          type={showPassword ? "text" : "password"}
                          onChange={handlePassChange}
                          value={password}
                          dir="ltr"
                          autocomplete="off"
                          maxLength={12}
                          placeholder="رمز عبور جدید خود را وارد کنید"
                        />
                        {passErrorMsg && (
                          <div>
                            <p className="more" style={{ color: "red" }}>
                              {passErrorMsg}
                            </p>
                            <br />
                          </div>
                        )}

                        {error.nPass && (
                          <p className="more" style={{ color: "red" }}>
                            {error.nPass}
                          </p>
                        )}
                      </div>

                      <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                        <button class="editB1">
                          {password.length > 0 && (
                            <button className="editB1">
                              <img
                                onClick={handleTogglePassword}
                                src={showPassword ? eyeOff : eyeOn}
                                alt=""
                              />
                            </button>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label for="rnp" class="mb-1 mt-4">
                      تکرار رمز عبور جدید
                    </label>
                    <div class="d-flex">
                      <div class="col-11">
                        <input
                          id="rnp"
                          autocomplete="off"
                          class="fgInputs rounded-end w-100 pHolder"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={handleConfirmPassChange}
                          dir="ltr"
                          maxLength={12}
                          placeholder="رمز عبور جدید خود را تکرار کنید"
                        />
                        {confirmPasswordErrorMsg && (
                          <div>
                            <p className="more" style={{ color: "red" }}>
                              {confirmPasswordErrorMsg}
                            </p>
                            <br />
                          </div>
                        )}

                        {error.cPass && (
                          <p className="more" style={{ color: "red" }}>
                            {error.pass}
                          </p>
                        )}
                      </div>
                      <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                        {confirmPassword.length > 0 && (
                          <button className="editB1">
                            <img
                              onClick={handleToggleConfirmPassword}
                              src={showConfirmPassword ? eyeOff : eyeOn}
                              alt=""
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="d-felx text-center mt-4">
                    <button
                      class="editB1 editB2"
                      onClick={() => setGoToLogin(true)}
                    >
                      برگشت به صفحه‌ی ورود
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    {loading ? (
                      <button class="loginButton-2 rounded border-0 text-light fw-bold px-5 py-2 mt-3 align-items-center">
                        <span class="loading"></span>
                      </button>
                    ) : (
                      <button
                        class="bColor rounded border-0 text-light fw-bold px-5 py-2 custom-mt"
                        type="submit"
                      >
                        تایید
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};
export default ForgetPass;
