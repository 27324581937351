import Login from "./components/Login_Components/Login";
import ForgetPass from "./components/fgpass_Components/forgetPass";
import NotFound from "./components/notFound_Components/notFound";
import Home from "./components/public_components/home";
import Havale from "./components/public_components/havale";
import Customer from "./components/admin_components/Customer_Components/customer";
import Employee from "./components/admin_components/employee_Component/employee";
import Reports from "./components/public_components/reports";
import ResetPass from "./components/public_components/resetPass";
const routes = [
  { path: "/login", element: <Login /> },
  { path: "/", element: <Home /> },
  { path: "/forgotpass", element: <ForgetPass /> },
  { path: "/home/havale", element: <Havale /> },
  { path: "/home/reports", element: <Reports /> },
  { path: "/home/employee", element: <Employee /> },
  { path: "/home/customer", element: <Customer /> },
  { path: "/home/resetPass", element: <ResetPass /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
