import React, { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import { debounce } from "lodash";
import axios from "axios";
import "../Customer_Components/customer.css";
import RightNav from "../home_Component/navs/adminRightNav";
import urls from "../../../url";
export default function AdminReports() {
  const [newRep, setNewRep] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showSearchWay, setShowSearchWay] = useState(false);
  const [reportExplain, setReportExplain] = useState("");
  const [way, setWay] = useState("");
  const [senderSearch, setSenderSearch] = useState(false);
  const [reciverSearch, setReciverSearch] = useState(false);
  const [amountSearch, setAmountSearch] = useState(false);
  const [code, setCode] = useState("");
  const [words, setWords] = useState("");
  useEffect(() => {
    getRepAdmin();
  }, []);
  const displayReasonValue = (value) => {
    return value || "-";
  };
  const displayStatusValue = (value) => {
    return value || "در حال بررسی";
  };
  const openExplain = (item) => {
    setShowPopup(true);
    setReportExplain(item.text_field || "توضیحی توسط کاربر وارد نشده است");
  };
  const closePopup = (e) => {
    e.preventDefault();
    setShowPopup(false);
  };
  const redOrGreen = (item) => {
    if (item.confirm && !item.reject) {
      return "green";
    } else if (!item.confirm && item.reject) {
      return "red";
    }
  };
  const getRepAdmin = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.adminReport}`)
      .then((response) => {
        setNewRep(response.data);
      })
      .catch((error) => {
        toast.error("مشکلی در دریافت لیست گزارشات وجود دارد");
      });
  };
  const nameSearchHandler = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      setWords(words);
      if (senderSearch) {
        senderSearchComponent(e);
      } else if (reciverSearch) {
        reciverSearchComponent(e)
      }
    } else {
      setWords("");
      toast.error("لطفا از حروف فارسی استفاده کنید");
    }
  };
  const amountSearchHandler = (e) => {
    const code = e.target.value;
    const regex = /^[0-9]+$/;
    if (regex.test(code)) {
      setCode(code);
      amountSearchComponent(e);
    } else {
      setCode("");
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const senderSearchComponent = debounce(async (e) => {
    const words = e.target.value;
    if (words.length >= 1) {
      try {
        const response = await axios.get(
          `${urls.searchSender}/?search=${encodeURIComponent(e.target.value)}`
        );
        setNewRep(response.data);
      } catch (error) {
        toast.error("مشکلی در ارتباط با سرور وجود دارد");
      }
    }else{
      getRepAdmin()
    }
  }, 300);
  const reciverSearchComponent = debounce(async (e) => {
    const words = e.target.value;
    if (words.length >= 1) {
      try {
        const response = await axios.get(
          `${urls.searchReciver}/?search=${encodeURIComponent(e.target.value)}`
        );
        setNewRep(response.data);
      } catch (error) {
        toast.error("مشکلی در ارتباط با سرور وجود دارد");
      }
    }else{
      getRepAdmin()
    }
  }, 300);
  const amountSearchComponent = debounce(async (e) => {
    const code = e.target.value;
    if (code.length >= 1) {
      try {
        const response = await axios.get(
          `${urls.searchAmount}/?search=${encodeURIComponent(e.target.value)}`
        );
        setNewRep(response.data);
      } catch (error) {
        toast.error("مشکلی در ارتباط با سرور وجود دارد");
      }
    }else{
      getRepAdmin()
    }
  }, 300);
  return (
    <body class="body">
      {showPopup && (
        <div class="popup-4 z-1 rounded-4 p-3">
          <div class="d-flex flex-column w-100 justify-content-end">
            <div>
              <button class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-2 p-0 border-end-0">
                <span class="d-inline-block icon32" onClick={closePopup}></span>
              </button>
            </div>
            <div className="d-flex flex-column">
              <label class="mb-2 mt-3 w-100 text-end user-select-none" for="tf ">
                توضیحات فرستنده
              </label>
              <textarea
                id="tf"
                class="rounded-2 p-1 w-100 textarea-4 user-select-none"
                minlength="10"
                value={reportExplain}
                style={{ resize: "none" }}
                maxlength="250"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      )}
      <div class="container-fluid cHeight d-flex p-0">
        <RightNav />
        <div class="col-10 px-4 pt-3 overflow-auto" dir="ltr">
          <Toaster
            richColors
            position="top-center"
            dir="rtl"
            visibleToasts={1}
            closeButton
          />
          <div class="d-flex flex-column" dir="rtl">
            <div class="d-flex text-black mt-4">
              <div class="btn-11 p-2 select-gray">
                <button class="border-0 bg-transparent user-select-none">
                  لیست حواله های فرستاده شده
                </button>
              </div>
              <div class="btn-5 p-2 d-flex align-items-center">
                <button
                  class="border-0 bg-transparent p-0 user-select-none"
                  onClick={() => {
                    getRepAdmin();
                  }}
                >
                  بروزرسانی
                </button>
                <button
                  class="icon21 border-0 bg-transparent"
                  onClick={() => {
                    getRepAdmin();
                  }}
                ></button>
              </div>
            </div>
            <div className="popup-6 z-1 rounded-4 p-3">
              <div class="w-75 position-relative">
                <div class="d-flex">
                  <label className="user-select-none">جستجو بر اساس</label>
                  <input
                    type="text"
                    value={way}
                    class="rounded-end-2 border-start-0 inp-3 me-4 p-1"
                    placeholder="انتخاب کنید"
                  />
                  <button
                    class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-start-0"
                    onClick={() => setShowSearchWay(!showSearchWay)}
                  >
                    <span class="d-inline-block icon30"></span>
                  </button>
                </div>
                {showSearchWay && (
                  <div class="d-flex list-margin">
                    <div class="list-group position-absolute rounded-0 list-width">
                      <div
                        class="list-group-item list-group-item-action text-start user-select-none"
                        onClick={(e) => {
                          setWay("نام فرستنده");
                          setShowSearchWay(false);
                          setSenderSearch(true);
                          setReciverSearch(false);
                          setAmountSearch(false);
                        }}
                      >
                        نام فرستنده
                      </div>
                      <div
                        class="list-group-item list-group-item-action text-start user-select-none"
                        onClick={(e) => {
                          setWay("نام گیرنده");
                          setShowSearchWay(false);
                          setSenderSearch(false);
                          setReciverSearch(true);
                          setAmountSearch(false);
                        }}
                      >
                        نام گیرنده
                      </div>
                      <div
                        class="list-group-item list-group-item-action text-start user-select-none"
                        onClick={(e) => {
                          setWay("وزن");
                          setShowSearchWay(false);
                          setSenderSearch(false);
                          setReciverSearch(false);
                          setAmountSearch(true);
                        }}
                      >
                        وزن
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(senderSearch || reciverSearch) && (
                <div class="d-flex w-100">
                  <input
                    id="chg"
                    type="text"
                    value={words}
                    class="rounded-end-2 border-start-0 inp-3 p-1 pe-2 farsi-digits"
                    onChange={(e) => nameSearchHandler(e)}
                    autoComplete="off"
                  />
                  <div class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-end-0">
                    <span class="d-inline-block reportIcon31"></span>
                  </div>
                </div>
              )}
              {amountSearch && (
                <div class="d-flex w-100">
                  <input
                    id="chg"
                    type="text"
                    value={code}
                    class="rounded-end-2 border-start-0 inp-3 p-1 pe-2 farsi-digits"
                    onChange={(e) => amountSearchHandler(e)}
                    autoComplete="off"
                  />
                  <div class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-end-0">
                    <span class="d-inline-block reportIcon31"></span>
                  </div>
                </div>
              )}
            </div>
            <div>
              <table class="Farsi-Digits table-5 w-100">
                <tr class="trow">
                  <td className="user-select-none">تاریخ</td>
                  <td className="user-select-none" style={{ width: "15%" }}>نام و نام خانوادگی فرستنده</td>
                  <td className="user-select-none">نام و نام خانوادگی گیرنده اولیه</td>
                  <td className="user-select-none">نام و نام خانوادگی گیرنده ثانویه</td>
                  <td className="user-select-none">وزن</td>
                  <td className="user-select-none">وضعیت</td>
                  <td className="user-select-none" style={{ width: "10%" }}>دلیل رد شدن</td>
                </tr>
                {newRep.map((item) => (
                  <tr
                    key={item.id}
                    className={redOrGreen(item)}
                    onClick={() => openExplain(item)}
                  >
                    <td className="user-select-none">
                      {item.jalali_date}-{item.tarikh}
                    </td>
                    <td className="user-select-none">{item.name_sender}</td>
                    <td className="user-select-none">{item.name_reciver}</td>
                    <td className="user-select-none">{displayReasonValue(item.second_name_reciver)}</td>
                    <td className="user-select-none">{item.weight}</td>
                    <td className="user-select-none">{displayStatusValue(item.status_text)}</td>
                    <td className="user-select-none">{displayReasonValue(item.reject_reason)}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
