import React, { useState, useEffect } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import RightNav from "../home_Component/navs/adminRightNav";
import "./employee.css";
import EmpYup from "./empYup";
import urls from "../../../url";
export default function Employee() {
  const [showEmpList, setShowEmpList] = useState(true);
  const [showEmpReg, setShowEmpReg] = useState(false);
  const [lastInput, setLastInput] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showSmsPopup, setShowSmsPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [product, setProduct] = useState("");
  const [prError, setPrError] = useState("");
  const [employeData, setEmployData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(null);
  useEffect(() => {
    getEmploye();
  }, []);
  const resetStates = () => {
    setName("");
    setPhoneNumber("");
    setRole("");
    setProduct("");
    setPrError("");
    setError({});
  };
  const waitSms = (index) => {
    setSelectedIndex(index);
    setShowSmsPopup(true);
  };
  const changeActiveStatus = (item, index) => {
    if (item.is_active) {
      changeToInactive(index, item.id);
    } else {
      changeToActive(index, item.id);
    }
  };
  const changeToInactive = async (index) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.inActive}/${employeData[index].id}/`,
        config
      );
      getEmploye();
      toast.success("کارمند با موفقیت غیرفعال شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const changeToActive = async (index) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.active}/${employeData[index].id}/`,
        config
      );
      getEmploye();
      toast.success("کارمند با موفقیت فعال شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const waitDelete = (index) => {
    setSelectedIndex(index);
    setShowPopup(true);
  };
  const editEmp = (item, index) => {
    setShowEmpList(false);
    setEditing(true);
    setShowEmpReg(true);
    setId(item.id);
    setName(item.name);
    setPhoneNumber(item.phone_number);
    setRole(item.role);
    setProduct(item.auth_product_for_trade);
    setSelectedIndex(index);
    setEditingEmployee(item);
    if (item.role === "مدیر محصولات") {
      setLastInput(true);
    } else {
      setLastInput(false);
    }
  };
  const sendEditedInfos = async (e) => {
    e.preventDefault();
    if (lastInput && product == "") {
      setPrError("پر کردن این فیلد اجباری است");
    } else if (
      editingEmployee.name === name &&
      editingEmployee.phone_number === phoneNumber &&
      editingEmployee.role === role &&
      editingEmployee.auth_product_for_trade === product
    ) {
      toast.error("لطفا برای ویرایش یکی از مقادیر را تغییر دهید");
    } else {
      setLoading(true)
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${urls.duTrader}/${employeData[selectedIndex].id}/`,
          {
            name: name,
            phone_number: phoneNumber,
            role: role,
            auth_product_for_trade: product,
          },
          config
        );
        getEmploye();
        setShowEmpList(true);
        setShowEmpReg(false);
        resetStates();
        toast.success("کارمند با موفقیت ویرایش شد");
        setLoading(false)
      } catch (error) {
        toast.error("مشکلی در ثبت درخواست وجود دارد");
        setLoading(false)
      }
      setPrError("");
    }
  };
  const empNameHandler = (e) => {
    const words = e.target.value;
    const regex = /[^\u0600-\u06FF\s]+$/;
    const newWords = words.replace(regex, "");
    if (words !== newWords) {
      toast.error("لطفا از حروف فارسی استفاده کنید");
    }
    setName(newWords);
  };
  const handleSmsPopupResponse = async (response) => {
    setShowSmsPopup(false);
    if (response === "yes") {
      await notifEmp(selectedIndex);
    }
    setSelectedIndex(null);
  };
  const notifEmp = async (index) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${urls.notif}/${employeData[index].id}/`,
        config
      );
      getEmploye();
      toast.success("پیامک با موفقیت برای کارمند ارسال شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const handlePopupResponse = async (response) => {
    setShowPopup(false);
    if (response === "yes") {
      await deleteTrader(selectedIndex);
    }
    setSelectedIndex(null);
  };
  const deleteTrader = async (index, id) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(
        `${urls.duTrader}/${employeData[index].id}/`,
        config
      );
      getEmploye();
      toast.success("کارمند با موفقیت حذف شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const sendApiInfos = async () => {
    try {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${urls.employee}`,
        {
          name: name,
          phone_number: phoneNumber,
          role: role,
          auth_product_for_trade: product,
        },
        config
      );
      toast.success("کارمند با موفقیت اضافه شد");
      getEmploye();
      setShowEmpList(true);
      setShowEmpReg(false);
      resetStates();
      setLoading(false)
    } catch (error) {
      toast.error("مشکلی در ثبت کارمند وجود دارد");
      setLoading(false)
    }
  };
  const getEmploye = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.employee}`)
      .then((response) => {
        setEmployData(response.data);
      })
      .catch((error) => {
        toast.error("مشکلی در دریافت لیست کارمندان وجود دارد");
      });
  };
  const roleIsA = () => {
    setRole("مدیر معاملاتی");
    setClicked(false);
    setLastInput(false);
  };
  const roleIsB = () => {
    setRole("مدیر محصولات");
    setClicked(false);
    setLastInput(true);
  };
  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) || newValue === '') {
      setPhoneNumber(newValue);
    } else {
      toast.error( "لطفاشماره تلفن را با ۰۹ شروع کرده و از اعداد استفاده کنید");
    }
  };
  const prHandler = (e) => {
    const pr = e.target.value;
    if (lastInput) {
      setProduct(pr);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const empYup = {
      name: e.target[0].value,
      phone: e.target[1].value,
      who: e.target[2].value,
    };
    try {
      const isValidYUP = await EmpYup.validate(empYup, {
        abortEarly: false,
      });
      if (isValidYUP) {
        if (lastInput && product == "") {
          setPrError("پر کردن این فیلد اجباری است");
        } else {
          setLoading(true)
          sendApiInfos();
          setPrError("");
        }
      }
    } catch (err) {
      let errors = err.inner
        ? err.inner.reduce(
            (acc, err) => ({
              ...acc,
              [err.path]: err.message,
            }),
            {}
          )
        : {};
      setError(errors);
    }
  };
  return (
    <body class="body">
      {showSmsPopup && (
        <div class="popup-1 z-1 rounded-4 p-3">
          <div>
            <p class="text-center fw-bold user-select-none">
              آیا از ارسال پیام برای این کارمند اطمینان دارید؟
            </p>
          </div>
          <div class="d-flex justify-content-center my-2 w-75">
            <button
              class="border-0 bg-white text-black rounded-2 rounded-end-0 fw-bold py-2 w-25 user-select-none"
              onClick={() => setShowSmsPopup(false)}
            >
              خیر
            </button>
            <button
              class="btn-16 border-0 bgpink text-white rounded-2 rounded-start-0 fw-bold py-2 w-25 user-select-none"
              onClick={() => handleSmsPopupResponse("yes")}
            >
              بله
            </button>
          </div>
        </div>
      )}
      {showPopup && (
        <div class="popup-1 z-1 rounded-4 p-3">
          <div>
            <p class="text-center fw-bold user-select-none">
              آیا از حذف این کارمند اطمینان دارید؟
            </p>
          </div>
          <div class="d-flex justify-content-center my-2 w-75">
            <button
              class="border-0 bg-white text-black rounded-2 rounded-end-0 fw-bold py-2 w-25 user-select-none"
              onClick={() => setShowPopup(false)}
            >
              خیر
            </button>
            <button
              class="btn-16 border-0 bgpink text-white rounded-2 rounded-start-0 fw-bold py-2 w-25 user-select-none"
              onClick={() => handlePopupResponse("yes")}
            >
              بله
            </button>
          </div>
        </div>
      )}
      <div class="container-fluid cHeight d-flex p-0">
        <RightNav />
        <div class="col-10 px-4 pt-3 overflow-auto" dir="ltr">
          <Toaster
            richColors
            position="top-center"
            dir="rtl"
            visibleToasts={1}
            closeButton
          />
          <div class="d-flex flex-column" dir="rtl">
            <div class="d-flex text-black mt-4">
              <div class={`btn-15 p-2 ${showEmpList ? "select-gray" : ""}`}>
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setShowEmpList(true);
                    setShowEmpReg(false);
                    setEditing(false);
                    setName("");
                    setPhoneNumber("");
                    setRole("");
                    setSelectedIndex("");
                    setLastInput(false);
                    setProduct("");
                  }}
                >
                  لیست کارمندان
                </button>
              </div>
              <div class={`btn-14 p-2 ${showEmpReg ? "select-gray" : ""}`}>
                <button
                  class={`border-0 bg-transparent `}
                  onClick={() => {
                    setShowEmpList(false);
                    setShowEmpReg(true);
                  }}
                >
                  ثبت کارمند جدید
                </button>
              </div>
              {showEmpList && (
                <div class="btn-5 p-2 d-flex align-items-center">
                  <button
                    class="border-0 bg-transparent p-0"
                    onClick={() => getEmploye()}
                  >
                    بروزرسانی
                  </button>
                  <button
                    class="icon21 border-0 bg-transparent"
                    onClick={() => getEmploye()}
                  ></button>
                </div>
              )}
            </div>
            {showEmpList && (
              <div class="col-11 d-flex">
                <table class="Farsi-Digits table-4 w-100 text-black">
                  <tr class="trow">
                    <td className="user-select-none">نام و نام خانوادگی</td>
                    <td className="user-select-none">شماره موبایل</td>
                    <td className="user-select-none">نقش</td>
                    <td className="user-select-none">عملیات</td>
                  </tr>
                  {employeData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="user-select-none">{item.name}</td>
                      <td className="user-select-none">{item.phone_number}</td>
                      <td className="user-select-none">{item.role}</td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <button
                            class="icon27 mx-1"
                            title="ویرایش اطلاعات کارمند"
                            onClick={() => editEmp(item, index)}
                          ></button>
                          <button
                            class="smsIcon mx-1"
                            title="اطلاع رسانی اطلاعات  کارمند بوسیله پیامک"
                            onClick={() => waitSms(index)}
                          ></button>
                          <button
                            class="icon16 mx-1"
                            title="حذف کارمند"
                            onClick={() => waitDelete(index)}
                          ></button>
                          <button
                            class={`${
                              item.is_active ? "icon25" : "unlockIcon"
                            } mx-1`}
                            title={`${item.is_active ? "غیرفعال کردن حساب کارمند" : "فعال کردن حساب کارمند"}`}
                            onClick={() => changeActiveStatus(item, index)}
                          ></button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
            {showEmpReg && (
              <form
                action=""
                onSubmit={editing ? sendEditedInfos : submitHandler}
              >
                <div class="col-9">
                  <div class="box-2 d-flex flex-column p-2">
                    <div class="p-1 d-flex flex-column mb-2">
                      <div class="d-flex">
                        <div class="col-6 ps-4">
                          <label class="text-color-1 my-1 user-select-none" for="fln">
                            نام و نام خانوادگی
                          </label>
                          <input
                            id="fln"
                            type="text"
                            onKeyPress={handleEnterPress}
                            value={name}
                            onChange={(e) => empNameHandler(e)}
                            class="w-100 rounded-2 inp-3 p-1"
                            autoComplete="off"
                          />
                          {error.name && (
                            <p className="more" style={{ color: "red" }}>
                              {error.name}
                            </p>
                          )}
                        </div>
                        <div class="col-6 pe-4">
                          <label class="text-color-1 my-1 user-select-none" for="mn">
                            شماره موبایل
                          </label>
                          <input
                            id="mn"
                            type="text"
                            value={phoneNumber}
                            autocomplete="off"
                            onKeyPress={handleEnterPress}
                            maxLength={11}
                            onChange={handlePhoneNumberChange}
                            class="w-100 rounded-2 inp-3 p-1 Farsi-Digits text-end"
                            dir="ltr"
                            autoComplete="off"
                          />
                          {error.phone && (
                            <p className="more" style={{ color: "red" }}>
                              {error.phone}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="col-6 ps-4">
                          <label class="text-color-1 mt-4 mb-1 user-select-none" for="hsv">
                            نقش
                          </label>
                          <div class="d-flex">
                            <input
                              id="hsv"
                              type="text"
                              value={role}
                              class="col-11 rounded-end-2 border-start-0 inp-3 p-1 ps-0"
                              placeholder="انتخاب کنید"
                              autoComplete="off"
                            />
                            <button
                              class="btn-7 d-flex justify-content-center align-items-center col-1 bg-white rounded-start-2 p-0"
                              onClick={(e) => {
                                e.preventDefault();
                                setClicked(!clicked);
                              }}
                            >
                              <span class="d-inline-block icon30"></span>
                            </button>
                          </div>
                          {error.who && (
                            <p className="more" style={{ color: "red" }}>
                              {error.who}
                            </p>
                          )}
                          {clicked && (
                            <div class="d-flex">
                              <div class="list-group position-absolute rounded-0 ">
                                <div
                                  class="list-group-item list-group-item-action text-start"
                                  onClick={roleIsA}
                                >
                                  مدیر معاملاتی
                                </div>
                                <div
                                  class="list-group-item list-group-item-action text-start"
                                  onClick={roleIsB}
                                >
                                  مدیر محصولات
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {lastInput && (
                          <div class="col-6 pe-4">
                            <label class="text-color-1 mt-4 mb-1 user-select-none" for="mmj">
                              محصولات مجاز برای معامله
                            </label>
                            <input
                              id="mmj"
                              type="text"
                              value={product}
                              onKeyPress={handleEnterPress}
                              onChange={prHandler}
                              class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                              autoComplete="off"
                            />
                            {prError && (
                              <div>
                                <p className="more" style={{ color: "red" }}>
                                  {prError}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <hr class="hr-1 mt-4 mb-3" />

                      <div class="d-flex justify-content-end">
                        {loading ? (
                          <button class="loginButton-2 rounded border-0 text-light fw-bold px-4 py-1 mt-2 align-items-center">
                            <span class="loading"></span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            class="btn-6 rounded border-0 text-light fw-bold px-4 py-1 mt-2"
                          >
                            {editing ? "ذخیره" : "ثبت"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </body>
  );
}
