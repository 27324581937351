import React, { useState, useEffect } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Rnav from "../userhome_Component/user_home_navs/userRightNav";
import urls from "../../../url";
import "../userhome_Component/userhome.css";
export default function UserHavale() {
  const [regHavale, setRegHavale] = useState(true);
  const [showHavale, setShowHavale] = useState(false);
  const [canselEdit, setCanselEdit] = useState(false);
  const [showSpan, setShowSpan] = useState(false);
  const [disable, setDisable] = useState(true);
  const [phone, setPhone] = useState("");
  const [editedHavale, setEditedHavale] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [explainErrorMsg, setExplainErrorMsg] = useState("");
  const [amountErrorMsg, setAmountErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [explains, setExplains] = useState("");
  const [existName, setExistName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getList, setGetList] = useState([]);
  useEffect(() => {
    const savedHavaleList = JSON.parse(localStorage.getItem("havaleList"));
    if (savedHavaleList) {
      setGetList(savedHavaleList);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;
      if (!token) {
        handleUnauthorized();
        return;
      }

      try {
        const response = await fetch(`${urls.checkToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          handleUnauthorized();
        }
      } catch (error) {
        toast.error("مشکلی در برقراری ارتباط با سرور وجود دارد");
      }
    };

    fetchData();
  }, []);
  const handleUnauthorized = () => {
    toast.error(
      "مهلت استفاده شما از سامانه به پایان رسید لطفا دوباره ورود کنید"
    );
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("havaleList");
    window.location.href = "/login";
  };
  const handlePhoneChange = (event) => {
    setPhoneErrorMsg("");
    setExistName(false)
    const newPhone = event.target.value;
    setPhone(newPhone);
    const regex = /^[0-9]+$/;
    if (!regex.test(newPhone)) {
      setPhoneErrorMsg("لطفا از اعداد استفاده کنید");
    } else if (!newPhone.startsWith("09")) {
      setPhoneErrorMsg("لطفا ۱۱ رقم وارد کنید و با ۰۹ آغاز کنید");
    } else {
      setPhoneErrorMsg("");
    }
    if (newPhone.length == 11) {
      setShowSpan(true);
    } else {
      setShowSpan(false);
    }
  };
  const handleNameChange = (event) => {
    const words = event.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      setName(words);
    } else {
      setName("");
      toast.error("لطفا از حروف فارسی استفاده کنید");
    }
  };
  const handleWeightChange = (event) => {
    setAmountErrorMsg("");
    const newAmount = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (newAmount === "" || regex.test(newAmount)) {
      setAmount(newAmount);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const handleExplainChange = (event) => {
    const words = event.target.value;
    const regex = /[a-zA-Z]+$/;
    if (regex.test(words)) {
      setExplains("");
      toast.error("لطفا از حروف انگلیسی استفاده نکنید");
    } else {
      setExplains(words);
      setExplainErrorMsg("");
    }
  };
  const sendHandler = () => {
    const newHavale = {
      id: Math.random().toString(36).substr(2, 9),
      phone_number_reciver: phone,
      name_reciver: name,
      weight: amount,
      text_field: explains,
      need_explain: existName,
    };

    const existingHavaleList =
      JSON.parse(localStorage.getItem("havaleList")) || [];

    const updatedHavaleList = [...existingHavaleList, newHavale];

    localStorage.setItem("havaleList", JSON.stringify(updatedHavaleList));
    setPhone("");
    setName("");
    setAmount("");
    setExplains("");
  };
  const check = (e) => {
    e.preventDefault();
    const localPhone = JSON.parse(localStorage.getItem("phoneNumber"));
    if (
      phone == "" ||
      phone.length < 11 ||
      name == "" ||
      amount == "" ||
      (existName === false && explains == "")
    ) {
      if (phone == "") {
        setPhoneErrorMsg("پر نمودن این فیلد الزامیست");
      }
      if (phone.length < 11) {
        setPhoneErrorMsg("شماره تلفن باید ۱۱ رقمی باشد");
      }
      if (name == "") {
        setNameErrorMsg("پر نمودن این فیلد الزامیست");
      }
      if (amount == "") {
        setAmountErrorMsg("پر نمودن این فیلد الزامیست");
      }
      if (existName === false && explains.length === 0) {
        setExplainErrorMsg("پر نمودن این فیلد اجباریست");
      }
    } else if (localPhone && phone == localPhone.phoneNumber) {
      setPhoneErrorMsg("نمیتوانید به خودتان حواله ارسال کنید");
    } else {
      setShowSpan(false);
      setExplainErrorMsg("");
      setPhoneErrorMsg("");
      setAmountErrorMsg("");
      setNameErrorMsg("");
      sendHandler();
      setDisable(false);
      setExistName(false);
      const savedHavaleList = JSON.parse(localStorage.getItem("havaleList"));
      if (savedHavaleList) {
        setGetList(savedHavaleList);
      }
      setRegHavale(false);
      setShowHavale(true);
    }
  };
  const takeInfos = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${urls.userPhone}`,
        {
          phone_number: phone,
        },
        config
      );
      setName(response.data);
      setExistName(true);
    } catch (error) {
      toast.error(
        "شماره چنین مشتری وجود ندارد لطفا اسم ،وزن و توضیحات را دستی وارد کنید"
      );
      setExistName(false);
      setName("");
    }
  };
  const calculateTotalWeight = () => {
    let totalWeight = 0;
    getList.forEach((item) => {
      totalWeight += parseFloat(item.weight);
    });
    return totalWeight.toFixed(3);
  };
  const edit = (item, index) => {
    setShowHavale(false);
    setRegHavale(true);
    setEditItem({ ...item, index });
    setShowSpan(true);
    setCanselEdit(true);
    setPhone(item.phone_number_reciver);
    setName(item.name_reciver);
    setAmount(item.weight);
    setExplains(item.text_field);
    setExistName(item.need_explain);
    setEditedHavale(item);
  };
  const saveEdit = (e) => {
    e.preventDefault();
    if (
      editedHavale.phone_number_reciver === phone &&
      editedHavale.name_reciver === name &&
      editedHavale.weight === amount &&
      editedHavale.text_field === explains
    ) {
      toast.error("لطفا برای ویرایش یکی از مقادیر را تغییر دهید");
    } else if (editItem) {
      const updatedList = getList.map((item, i) => {
        if (i === editItem.index) {
          return {
            ...item,
            phone_number_reciver: phone,
            name_reciver: name,
            weight: amount,
            text_field: explains,
          };
        }
        return item;
      });
      setGetList(updatedList);
      localStorage.setItem("havaleList", JSON.stringify(updatedList));
      setEditItem(null);
      setShowSpan(false);
      setCanselEdit(false);
      setRegHavale(false);
      setCanselEdit(false);
      setShowHavale(true);
      setPhone("");
      setName("");
      setAmount("");
      setExplains("");
      setExistName(false);
      toast.success("ویرایش با موفقیت انجام شد");
    }
  };
  const deleteHandler = (index) => {
    const updatedList = [...getList];
    updatedList.splice(index, 1);
    setGetList(updatedList);
    localStorage.setItem("havaleList", JSON.stringify(updatedList));
    toast.success("حواله با موفقیت حذف شد");
  };
  const sendTransfers = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await Promise.all(
        getList.map(async (item) => {
          const response = await axios.post(
            `${urls.userHavale}`,
            {
              phone_number_reciver: item.phone_number_reciver,
              name_reciver: item.name_reciver,
              weight: item.weight,
              text_field: item.text_field,
            },
            config
          );
        })
      );
      setGetList([]);
      localStorage.removeItem("havaleList");
      setRegHavale(true);
      setShowHavale(false);
      setDisable(true);
      setLoading(false);
      toast.success("حوالجات با موفقیت به سمت حسابدار ارسال شد");
    } catch (error) {
      toast.error("مشکلی در ارسال حوالجات وجود دارد");
      setLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (showSpan) {
      if (event.key === "Enter") {
        takeInfos(event);
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const cansel = (e) => {
    setRegHavale(false);
    setShowHavale(true);
    setCanselEdit(false);
    setEditItem(null);
    setShowSpan(false);
    setRegHavale(false);
    setShowHavale(true);
    setPhone("");
    setName("");
    setAmount("");
    setExplains("");
    setExistName(false);
  };
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <body class="body">
      <div class="container-fluid cHeight d-flex p-0">
        <div class="col-9 d-flex">
          <Rnav />
          <div class="col-10 px-4 d-flex pt-3 flex-column">
            <Toaster
              richColors
              position="top-center"
              dir="rtl"
              visibleToasts={1}
              closeButton
            />
            <div class="d-flex text-black">
              <div class={`btn-1 p-2 ${regHavale ? "select-gray" : ""}`}>
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setRegHavale(true);
                    setShowHavale(false);
                  }}
                >
                  ثبت حواله جدید
                </button>
              </div>
              <div class={`btn-2 p-2 ${showHavale ? "select-gray" : ""}`}>
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setRegHavale(false);
                    setShowHavale(true);
                  }}
                >
                  لیست حواله‌ها
                </button>
              </div>
            </div>

            {regHavale && (
              <form class="d-flex form-1 col-9 flex-column">
                <div class="d-flex col-12">
                  <div class="col-6 p-3">
                    <label for="mn" class="d-block text-black mb-1 user-select-none">
                      شماره موبایل
                    </label>
                    <div class="d-flex w-100">
                      <input
                        id="mn"
                        type="text"
                        onKeyPress={handleKeyPress}
                        onChange={handlePhoneChange}
                        value={phone}
                        maxLength={11}
                        autoComplete="off"
                        class="inp-2 w-100 rounded-end-1 border-start-0 text-end p-1 Farsi-Digits"
                      />
                      <button class="col-1 btn-user d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-end-0" onClick={takeInfos}>
                        {showSpan && (
                          <span
                            class="d-inline-block userIcon31"
                            onClick={takeInfos}
                          ></span>
                        )}
                      </button>
                    </div>
                    <div>
                      {phoneErrorMsg && (
                        <div>
                          <p className="more" style={{ color: "red" }}>
                            {phoneErrorMsg}
                          </p>
                          <br />
                        </div>
                      )}
                    </div>

                    <label for="name" class="d-block text-black mb-1 mt-4 user-select-none">
                      نام و نام خانوادگی
                    </label>
                    <input
                      id="name"
                      onChange={handleNameChange}
                      value={name}
                      onKeyPress={handleEnterPress}
                      autoComplete="off"
                      type="text"
                      class="inp-2 w-100 rounded-1 text-end p-1"
                    />
                    {nameErrorMsg && (
                      <div>
                        <p className="more" style={{ color: "red" }}>
                          {nameErrorMsg}
                        </p>
                        <br />
                      </div>
                    )}
                    <label for="mn" class="d-block text-black mb-1 mt-4 user-select-none">
                      وزن <small >(گرم)</small>
                    </label>
                    <input
                      id="mn"
                      onChange={handleWeightChange}
                      value={amount}
                      onKeyPress={handleEnterPress}
                      autoComplete="off"
                      type="text"
                      class="inp-2 w-100 rounded-1 text-end p-1 Farsi-Digits"
                    />
                    {amountErrorMsg && (
                      <div>
                        <p className="more" style={{ color: "red" }}>
                          {amountErrorMsg}
                        </p>
                        <br />
                      </div>
                    )}
                  </div>

                  <div class="col-6 p-3">
                    <label class="d-block text-black mb-1 user-select-none">توضیحات</label>
                    <textarea
                      onChange={handleExplainChange}
                      value={explains}
                      style={{ resize: "none" }}
                      autoComplete="off"
                      class="w-100 rounded-1 tar-1 p-1"
                      placeholder={`${existName ? "اختیاری" : "اجباری"}`}
                    />
                    {explainErrorMsg && (
                      <div>
                        <p className="more" style={{ color: "red" }}>
                          {explainErrorMsg}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <hr class="hr-1 mx-3 my-0" />
                <div class="d-flex col-12 justify-content-end">
                  {canselEdit && (
                    <button
                      class="m-3 mb-2 rounded-3 border-0 bg-white fw-bold px-4 py-1 user-select-none"
                      onClick={() => cansel()}
                    >
                      بازگشت
                    </button>
                  )}
                  <button
                    className="border-0 m-3 mb-2 rounded-3 btn-4 text-white px-4 py-1 fw-bold"
                    onClick={editItem ? saveEdit : check}
                  >
                    {editItem ? "ویرایش" : "ذخیره"}
                  </button>
                </div>
              </form>
            )}

            {showHavale && (
              <div>
                {getList.length > 0 ? (
                  <table class="Farsi-Digits table-2 w-100">
                    <tr class="trow">
                      <td className="user-select-none" style={{ width: 9 + "%" }}>کد حساب</td>
                      <td className="user-select-none">نام و نام خانوادگی</td>
                      <td className="user-select-none">شماره موبایل</td>
                      <td className="user-select-none">وزن</td>
                      <td className="user-select-none" style={{ width: 13 + "%" }}>عملیات‌ها</td>
                    </tr>
                    {getList.map((item, index) => (
                      <tr key={index}>
                        <td className="user-select-none">{index + 1}</td>
                        <td className="user-select-none">{item.name_reciver}</td>
                        <td className="user-select-none">{item.phone_number_reciver}</td>
                        <td className="user-select-none">{item.weight} گرم</td>
                        <td className="d-flex align-items-center justify-content-around">
                          <button
                            className="icon15"
                            onClick={() => edit(item, index)}
                          ></button>
                          <button
                            className="icon16"
                            onClick={() => deleteHandler(index)}
                          ></button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td class="border-start border-black p-0">
                        <div class="d-flex">
                          <div class="col-6"></div>
                          <div class="col-6 bg-white td-1 user-select-none">وزن نهایی</div>
                        </div>
                      </td>
                      <td className="bg-white border-end border-black user-select-none">
                        {calculateTotalWeight()} گرم
                      </td>
                      <td class="p-0">
                        <div class="d-flex">
                          <button
                            class="col-3 p-1 td-btn-1 fs-4"
                            onClick={() => {
                              setRegHavale(true);
                              setShowHavale(false);
                            }}
                          >
                            +
                          </button>
                          {loading ? (
                            <button class="col-9 td-btn-2">
                              <span class="loading"></span>
                            </button>
                          ) : (
                            <button
                              class="col-9 td-btn-2"
                              onClick={sendTransfers}
                            >
                              تایید نهایی
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>
                ) : (
                  <div className="popup-5 z-1 rounded-4 p-3 user-select-none">
                    لیست حواله‌ها خالی است.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
}
