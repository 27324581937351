import * as Yup from 'yup';
const CustomerYup= Yup.object().shape({
    name: Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    phone:Yup.string().min(11,"شماره موبایل باید شامل یازده عدد باشد").required("وارد کردن شماره موبایل اجباریست"),
    minW: Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    minN: Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    maxW: Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    maxN: Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    goroh:Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    modir:Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    wVal:Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
    numOfUser:Yup.string().required("وارد کردن اطلاعات این بخش اجباریست"),
})
export default CustomerYup;