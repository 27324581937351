import React, { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import { debounce } from "lodash";
import Rnav from "../userhome_Component/user_home_navs/userRightNav";
import axios from "axios";
import urls from "../../../url";
import "../../admin_components/Customer_Components/customer.css";
export default function UserReports() {
  const [newSendedData, setNewSendedData] = useState([]);
  const [newRecData, setNewRecData] = useState([]);
  const [sendList, setSendList] = useState(true);
  const [recList, setRecList] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [reportExplain, setReportExplain] = useState("");
  const [showSearchWay, setShowSearchWay] = useState(false);
  const [way, setWay] = useState("");
  const [senderSearch, setSenderSearch] = useState(false);
  const [reciverSearch, setReciverSearch] = useState(false);
  const [amountSearch, setAmountSearch] = useState(false);
  const [code, setCode] = useState("");
  const [words, setWords] = useState("");
  useEffect(() => {
    getSendList();
    getRecList();
  }, []);
  const displayReasonValue = (value) => {
    return value || "-";
  };
  const displayStatusValue = (value) => {
    return value || "در حال بررسی";
  };
  const redOrGreen = (item) => {
    if (item.confirm && !item.reject) {
      return "green";
    } else if (!item.confirm && item.reject) {
      return "red";
    }
  };
  const getSendList = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.senderReport}`)
      .then((response) => {
        setNewSendedData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        toast.error("مشکلی در بارگیری اطلاعات وجود دارد");
      });
  };
  const getRecList = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.reciverReport}`)
      .then((response) => {
        setNewRecData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        toast.error("مشکلی در بارگیری اطلاعات وجود دارد");
      });
  };
  const closePopup = (e) => {
    e.preventDefault();
    setShowPopup(false);
  };
  const openExplain = (item) => {
    setShowPopup(true);
    setReportExplain(item.text_field || "توضیحی توسط کاربر وارد نشده است");
  };
  return (
    <body class="body">
      <Toaster
        richColors
        position="top-center"
        dir="rtl"
        visibleToasts={1}
        closeButton
      />
      {showPopup && (
        <div class="popup-4 z-1 rounded-4 p-3">
          <div class="d-fle flex-column w-100 justify-content-end">
            <div>
              <button class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-2 p-0 border-end-0">
                <span class="d-inline-block icon32" onClick={closePopup}></span>
              </button>
            </div>
            <div className="d-flex flex-column">
              <label class="mb-2 mt-3 w-100 text-end user-select-none" for="tf ">
                توضیحات فرستنده
              </label>
              <textarea
                id="tf"
                class="rounded-2 p-1 w-100 textarea-4"
                minlength="10"
                value={reportExplain}
                style={{ resize: "none" }}
                maxlength="250"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      )}
      <div class="container-fluid cHeight d-flex p-0">
        <Rnav />
        <div class="col-10 px-4 pt-3 overflow-auto" dir="ltr">
          <div class="d-flex flex-column" dir="rtl">
            <div class="d-flex text-black mt-4">
              <div class={`btn-11 p-2 ${sendList ? "select-gray" : ""}`}>
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setSendList(true);
                    setRecList(false);
                  }}
                >
                  لیست حواله های فرستاده شده
                </button>
              </div>
              <div class={`btn-12 p-2 ${recList ? "select-gray" : ""}`}>
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setSendList(false);
                    setRecList(true);
                  }}
                >
                  لیست حواله‌های دریافت شده
                </button>
              </div>
              <div class="btn-5 p-2 d-flex align-items-center">
                <button
                  class="border-0 bg-transparent p-0"
                  onClick={() => {
                    getSendList();
                    getRecList();
                  }}
                >
                  بروزرسانی
                </button>
                <button
                  class="icon21 border-0 bg-transparent"
                  onClick={() => {
                    getSendList();
                    getRecList();
                  }}
                ></button>
              </div>
            </div>
            {sendList && (
              <div class="col-11 ">
                {sendList > 0 ? (
                  <table class="Farsi-Digits table-5 w-100 ">
                    <tr class="trow">
                      <td className="user-select-none">تاریخ</td>
                      <td className="user-select-none" style={{ width: "15%" }}>
                        نام و نام خانوادگی فرستنده
                      </td>
                      <td className="user-select-none">نام و نام خانوادگی گیرنده اولیه</td>
                      <td className="user-select-none">نام و نام خانوادگی گیرنده ثانویه</td>
                      <td className="user-select-none">وزن</td>
                      <td className="user-select-none">وضعیت</td>
                      <td className="user-select-none" style={{ width: "10%" }}>دلیل رد شدن</td>
                    </tr>
                    {newSendedData.map((item) => (
                      <tr
                        key={item.id}
                        className={redOrGreen(item)}
                        onClick={() => openExplain(item)}
                      >
                        <td className="user-select-none">
                          {item.jalali_date}-{item.tarikh}
                        </td>
                        <td className="user-select-none">{item.name_sender}</td>
                        <td className="user-select-none">{item.name_reciver}</td>
                        <td className="user-select-none">{displayReasonValue(item.second_name_reciver)}</td>
                        <td className="user-select-none">{item.weight}</td>
                        <td className="user-select-none">{displayStatusValue(item.status_text)}</td>
                        <td className="user-select-none">{displayReasonValue(item.reject_reason)}</td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <p className="popup-5 z-1 rounded-4 p-3 user-select-none">
                    حواله ای برای نمایش وجود ندارد
                  </p>
                )}
              </div>
            )}
            {recList && (
              <div class="col-9">
                {recList > 0 ? (
                  <table class="Farsi-Digits table-5 w-100">
                    <tr class="trow">
                      <td className="user-select-none">تاریخ</td>
                      <td className="user-select-none" style={{ width: "15%" }}>
                        نام و نام خانوادگی فرستنده
                      </td>
                      <td className="user-select-none">نام و نام خانوادگی گیرنده اولیه</td>
                      <td className="user-select-none">نام و نام خانوادگی گیرنده ثانویه</td>
                      <td className="user-select-none">وزن</td>
                      <td className="user-select-none">وضعیت</td>
                      <td className="user-select-none" style={{ width: "10%" }}>دلیل رد شدن</td>
                    </tr>
                    {newRecData.map((item) => (
                      <tr
                        key={item.id}
                        className={redOrGreen(item)}
                        onClick={() => openExplain(item)}
                      >
                        <td className="user-select-none">{item.jalali_date}</td>
                        <td className="user-select-none">{item.name_sender}</td>
                        <td className="user-select-none">{item.name_reciver}</td>
                        <td className="user-select-none">{displayReasonValue(item.second_name_reciver)}</td>
                        <td className="user-select-none">{item.weight}</td>
                        <td className="user-select-none">{displayStatusValue(item.status_text)}</td>
                        <td className="user-select-none">{displayReasonValue(item.reject_reason)}</td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <p className="popup-5 z-1 rounded-4 p-3 user-select-none">
                    حواله ای برای نمایش وجود ندارد
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
}
