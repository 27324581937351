import React, { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import { debounce } from "lodash";
import axios from "axios";
import "./customer.css";
import RightNav from "../home_Component/navs/adminRightNav";
import CustomerYup from "./customerYup";
import { FaExclamationTriangle } from "react-icons/fa";
import urls from "../../../url";
export default function Customer() {
  const [registredData, setRegistredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newData, setNewData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editingCus, setEditingCus] = useState(null);
  const [showList, setShowList] = useState(true);
  const [showNewRegisterList, setShowNewRegisterList] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSmsPopup, setShowSmsPopup] = useState(false);
  const [fullName, setFullName] = useState("");
  const [code, setCode] = useState("");
  const [minw, setMinw] = useState("");
  const [minn, setMinn] = useState("");
  const [maxw, setMaxw] = useState("");
  const [maxn, setMaxn] = useState("");
  const [groupe, setGroupe] = useState("");
  const [modir, setModir] = useState("");
  const [wval, setWval] = useState("");
  const [users, setUsers] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchCode, setSearchCode] = useState();
  const [way, setWay] = useState("");
  const [showSearchWay, setShowSearchWay] = useState(false);
  const [nameSearch, setNameSearch] = useState(false);
  const [codeSearch, setCodeSearch] = useState(false);
  const [words, setWords] = useState("");
  useEffect(() => {
    getNewUser();
    getRegistredUser();
  }, []);
  const resetAllStates = () => {
    setPhoneNumber("");
    setFullName("");
    setCode("");
    setMinw("");
    setMinn("");
    setMaxw("");
    setMaxn("");
    setGroupe("");
    setModir("");
    setWval("");
    setUsers("");
    setError({});
    setSelectedUser("");
  };
  const changeActiveStatus = (index,item) => {
    if (item.is_active) {
      changeToInactive(index);
    } else {
      changeToActive(index);
    }
  };
  const changeToInactive = async (index) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.inActive}/${registredData[index].id}/`,
        config
      );
      getNewUser();
      getRegistredUser();
      toast.success("مشتری با موفقیت غیرفعال شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const changeToActive = async (index) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.active}/${registredData[index].id}/`,
        config
      );
      getNewUser();
      getRegistredUser();
      toast.success("مشتری با موفقیت فعال شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const updateCostumer = async(index) =>{
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.updateCostumer}/${registredData[index].id}/`,
        config
      );
      getRegistredUser();
      toast.success("مشتری با موفقیت بروزرسانی شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  }
  const nameHandler = (e) => {
    const words = e.target.value;
    const regex = /[^\u0600-\u06FF\s]+$/;
    const newWords = words.replace(regex, "");
    if (words !== newWords) {
      toast.error("لطفا از حروف فارسی استفاده کنید");
    }
    setFullName(newWords);
  };
  const modirHandler = (e) => {
    const words = e.target.value;
    const regex = /[^\u0600-\u06FF\s]+$/;
    const newWords = words.replace(regex, "");
    if (words !== newWords) {
      toast.error("لطفا از حروف فارسی استفاده کنید");
    }
    setModir(newWords);
  };
  const goToRigester = (item) => {
    if (item.tel == "") {
      toast.error("لطفا در ته حساب اطلاعات مورد نیاز را کامل کنید");
    } else {
      setSelectedUser(item);
      setShowNewRegisterList(false);
      setShowRegister(true);
    }
  };
  const waitSms = (index) => {
    setSelectedIndex(index);
    setShowSmsPopup(true);
  };
  const handleSmsPopupResponse = async (response) => {
    setShowSmsPopup(false);
    if (response === "yes") {
      await notifUser(selectedIndex);
    }
    setSelectedIndex(null);
  };
  const notifUser = async (index) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${urls.notif}/${registredData[index].id}/`,
        config
      );
      getNewUser();
      getRegistredUser();
      toast.success("پیامک با موفقیت برای مشتری ارسال شد");
    } catch (error) {
      toast.error("مشکلی در ثبت درخواست وجود دارد");
    }
  };
  const editCustomer = (item, index) => {
    setShowList(false);
    setShowNewRegisterList(false);
    setShowRegister(true);
    setEditing(true);
    setSelectedIndex(index);
    setEditingCus(item);
    setPhoneNumber(item.phone_number);
    setFullName(item.name);
    setCode(item.trades_code);
    setMinw(item.min_weight);
    setMinn(item.min_number);
    setMaxw(item.max_weight);
    setMaxn(item.max_number);
    setGroupe(item.group_user);
    setModir(item.admin_trade);
    setWval(item.credit_user_by_weight);
    setUsers(item.number_of_users_for_login);
  };
  const getNewUser = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.clientInfo}`)
      .then((response) => {
        setNewData(response.data);
      })
      .catch((error) => {
        toast.error("مشکلی در دریافت لیست افراد پیش ثبت نام شده وجود دارد");
      });
  };
  const getRegistredUser = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.registredByAdmin}`)
      .then((response) => {
        setRegistredData(response.data);
      })
      .catch((error) => {
        toast.error("مشکلی در دریافت لیست مشتریان وجود دارد");
      });
  };
  const handleRefresh = () => {
    if (showList) {
      getRegistredUser();
    } else if (showNewRegisterList) {
      getNewUser();
    }
  };
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const customerYup = {
      name: e.target[1].value,
      phone: e.target[2].value,
      minW: e.target[9].value,
      minN: e.target[10].value,
      maxW: e.target[11].value,
      maxN: e.target[12].value,
      goroh: e.target[13].value,
      modir: e.target[15].value,
      wVal: e.target[16].value,
      numOfUser: e.target[17].value,
    };
    try {
      const isValidYUP = await CustomerYup.validate(customerYup, {
        abortEarly: false,
      });
      if (isValidYUP) {
        if (
          parseInt(maxn) < parseInt(minn) ||
          parseInt(maxw) < parseInt(minw)
        ) {
          toast.error("مقادیر وزن و تعداد را با دقت وارد کنید");
        } else if (selectedUser) {
          setLoading(true);
          postSelectedInfos();
        } else {
          setLoading(true);
          postInfos();
        }
      }
    } catch (err) {
      let errors = err.inner
        ? err.inner.reduce(
            (acc, err) => ({
              ...acc,
              [err.path]: err.message,
            }),
            {}
          )
        : {};
      setError(errors);
    }
  };
  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) || newValue === "") {
      setPhoneNumber(newValue);
    } else {
      toast.error("لطفاشماره تلفن را با ۰۹ شروع کرده و از اعداد استفاده کنید");
    }
  };
  const minwHandler = (e) => {
    const minwText = e.target.value;
    if (/^\d*$/.test(minwText)) {
      setMinw(minwText);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const minnHandler = (e) => {
    const minnText = e.target.value;
    if (/^\d*$/.test(minnText)) {
      setMinn(minnText);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const maxwHandler = (e) => {
    const maxwText = e.target.value;
    if (/^\d*$/.test(maxwText)) {
      setMaxw(maxwText);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const maxnHandler = (e) => {
    const maxnText = e.target.value;
    if (/^\d*$/.test(maxnText)) {
      setMaxn(maxnText);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const wValHandler = (e) => {
    const wValText = e.target.value;
    if (/^\d*$/.test(wValText)) {
      setWval(wValText);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const userHandler = (e) => {
    const userText = e.target.value;
    if (/^\d*$/.test(userText)) {
      setUsers(userText);
    } else {
      toast.error("لطفا از اعداد استفاده کنید");
    }
  };
  const exit = (e) => {
    e.preventDefault();
    resetAllStates();
    setShowRegister(false);
    setShowNewRegisterList(true);
  };
  const editExit = (e) => {
    e.preventDefault();
    resetAllStates();
    setShowRegister(false);
    setShowList(true);
  };
  const haveItemsChanged = (original, edited) => {
    for (const key in original) {
      if (original.hasOwnProperty(key) && edited.hasOwnProperty(key)) {
        if (original[key] !== edited[key]) {
          return true;
        }
      }
    }
    return false;
  };
  const editHandler = async (e) => {
    e.preventDefault();
    const editedItems = {
      phone_number: phoneNumber,
      name: fullName,
      min_weight: minw,
      min_number: minn,
      max_weight: maxw,
      max_number: maxn,
      group_user: groupe,
      admin_trade: modir,
      credit_user_by_weight: wval,
      number_of_users_for_login: users,
    };
    if (haveItemsChanged(editingCus, editedItems)) {
      setLoading(true);
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.post(
          `${urls.duClient}/${registredData[selectedIndex].id}/`,
          {
            phone_number: phoneNumber,
            name: fullName,
            min_weight: minw,
            min_number: minn,
            max_weight: maxw,
            max_number: maxn,
            group_user: groupe,
            admin_trade: modir,
            credit_user_by_weight: wval,
            number_of_users_for_login: users,
          },
          config
        );
        resetAllStates();
        setShowList(true);
        setShowNewRegisterList(false);
        setShowRegister(false);
        setEditing(false);
        getNewUser();
        getRegistredUser();
        toast.success("مشتری با موفقیت ویرایش شد");
        setLoading(false);
      } catch (error) {
        toast.error("مشکلی در ویرایش مشتری وجود دارد");
        setLoading(false);
      }
    } else {
      toast.error("لطفا برای ویرایش یکی از مقادیر را تغییر دهید");
    }
  };
  const postInfos = async () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.registredByAdmin}`,
        {
          phone_number: phoneNumber,
          name: fullName,
          min_weight: minw,
          min_number: minn,
          max_weight: maxw,
          max_number: maxn,
          group_user: groupe,
          admin_trade: modir,
          credit_user_by_weight: wval,
          number_of_users_for_login: users,
        },
        config
      );
      resetAllStates();
      setShowList(false);
      setShowNewRegisterList(true);
      setShowRegister(false);
      getNewUser();
      getRegistredUser();
      toast.success("مشتری با موفقیت ثبت شد");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("مشکلی در ثبت مشتری وجود دارد");
    }
  };
  const postSelectedInfos = async (e) => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.registredByAdmin}`,
        {
          phone_number: selectedUser.tel,
          name: selectedUser.name,
          min_weight: minw,
          min_number: minn,
          max_weight: maxw,
          max_number: maxn,
          group_user: selectedUser.gorooh,
          admin_trade: modir,
          credit_user_by_weight: wval,
          number_of_users_for_login: users,
        },
        config
      );
      resetAllStates();
      getNewUser();
      getRegistredUser();
      setShowList(false);
      setShowNewRegisterList(true);
      setShowRegister(false);
      toast.success("مشتری با موفقیت ثبت شد");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("مشکلی در ثبت مشتری وجود دارد");
    }
  };
  const amountSearchHandler = debounce(async (e) => {
    const searchedCode = e.target.value;
    const regex = /^[0-9]+$/;
    if (searchedCode == "") {
      getNewUser();
    } else if (regex.test(searchedCode)) {
      setSearchCode(searchCode);
      try {
        const response = await axios.post(`${urls.customerSearch}`, {
          code: searchedCode,
        });
        setNewData(response.data);
      } catch (error) {
        toast.error("مشکلی در ارتباط با سرور وجود دارد");
      }
    } else {
      setSearchCode("");

      toast.error("لطفا از اعداد استفاده کنید");
    }
  }, 300);
  const isPhoneNumberEmpty = (item) => {
    if (item.tel == "") {
      return <FaExclamationTriangle style={{ color: "orange" }} />;
    } else {
      return "";
    }
  };
  const isNameEmpty = (item) => {
    if (item.name == "") {
      return <FaExclamationTriangle style={{ color: "orange" }} />;
    } else {
      return "";
    }
  };
  const nameSearchHandler = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسيیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      setWords(words);
      nameSearchComponent(e);
    } else {
      setWords("");
      toast.error("لطفا از حروف فارسی استفاده کنید");
    }
  };
  const nameSearchComponent = debounce(async (e) => {
    const words = e.target.value;
    if (words.length >= 1) {
      try {
        const response = await axios.get(
          `${urls.nameSearch}?name=${encodeURIComponent(words)}`
        );
        setNewData(response.data);
      } catch (error) {
        toast.error("مشکلی در ارتباط با سرور وجود دارد");
      }
    } else {
      getNewUser();
    }
  }, 300);
  return (
    <body class="body">
      {showSmsPopup && (
        <div class="popup-1 z-1 rounded-4 p-3">
          <div>
            <p class="text-center fw-bold">
              آیا از ارسال پیام برای این مشتری اطمینان دارید؟
            </p>
          </div>
          <div class="d-flex justify-content-center my-2 w-75">
            <button
              class="border-0 bg-white text-black rounded-2 rounded-end-0 fw-bold py-2 w-25"
              onClick={() => setShowSmsPopup(false)}
            >
              خیر
            </button>
            <button
              class="btn-16 border-0 bgpink text-white rounded-2 rounded-start-0 fw-bold py-2 w-25"
              onClick={() => handleSmsPopupResponse("yes")}
            >
              بله
            </button>
          </div>
        </div>
      )}
      <div class="container-fluid cHeight d-flex p-0">
        <RightNav />
        <div class="col-10 px-4 pt-3 overflow-auto" dir="ltr">
          <Toaster
            richColors
            position="top-center"
            dir="rtl"
            visibleToasts={1}
            closeButton
          />
          <div class="d-flex flex-column" dir="rtl">
            <div class="d-flex text-black mt-4">
              <div class={`btn-11 p-2 ${showList ? "select-gray" : ""}`}>
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setShowList(true);
                    setShowNewRegisterList(false);
                    setShowRegister(false);
                  }}
                  disabled={showRegister}
                >
                  لیست مشتریان
                </button>
              </div>
              <div
                class={`btn-12 p-2 ${
                  showRegister || showNewRegisterList ? "select-gray" : ""
                }`}
              >
                <button
                  class="border-0 bg-transparent"
                  onClick={() => {
                    setShowList(false);
                    setShowNewRegisterList(true);
                    setShowRegister(false);
                  }}
                  disabled={showRegister}
                >
                  ثبت مشتری جدید
                </button>
              </div>
              {!showRegister && (
                <div class="btn-5 p-2 d-flex align-items-center">
                  <button
                    class="border-0 bg-transparent p-0"
                    onClick={handleRefresh}
                  >
                    بروزرسانی
                  </button>
                  <button
                    class="icon21 border-0 bg-transparent"
                    onClick={handleRefresh}
                  ></button>
                </div>
              )}
            </div>
            {showList && (
              <div class="d-flex flex-column overflow-auto" dir="rtl">
                <table className="Farsi-Digits table-4 w-100 text-black">
                  <tr className="trow">
                    <td className="user-select-none">کد معاملاتی</td>
                    <td className="user-select-none">نام و نام خانوادگی</td>
                    <td className="user-select-none">حداقل وزن</td>
                    <td className="user-select-none">حداقل تعداد</td>
                    <td className="user-select-none">حداکثر وزن</td>
                    <td className="user-select-none">حداکثر تعداد</td>
                    <td className="user-select-none">اعتبار</td>
                    <td className="user-select-none">شماره موبایل</td>
                    <td className="user-select-none">مدیر معاملات</td>
                    <td className="user-select-none">گروه مشتری</td>
                    <td className="user-select-none">عملیات</td>
                  </tr>
                  {registredData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="user-select-none">{item.trades_code}</td>
                      <td className="user-select-none">{item.name}</td>
                      <td className="user-select-none">{item.min_weight}</td>
                      <td className="user-select-none">{item.min_number}</td>
                      <td className="user-select-none">{item.max_weight}</td>
                      <td className="user-select-none">{item.max_number}</td>
                      <td className="user-select-none">
                        {item.credit_user_by_weight}
                      </td>
                      <td className="user-select-none">{item.phone_number}</td>
                      <td className="user-select-none">{item.admin_trade}</td>
                      <td className="user-select-none">{item.group_user}</td>
                      <td>
                        <div class="d-flex justify-content-around">
                          <button
                            class="icon27 mx-1"
                            title="ویرایش کردن"
                            onClick={() => editCustomer(item, index)}
                          ></button>
                          <button
                            class="smsIcon mx-1"
                            title="اطلاع رسانی اطلاعات  مشتری بوسیله پیامک"
                            onClick={() => waitSms(index)}
                          ></button>
                          <button
                            class={`${
                              item.is_active ? "icon25" : "unlockIcon"
                            } mx-1`}
                            title={`${
                              item.is_active
                                ? "غیرفعال کردن حساب مشتری"
                                : "فعال کردن حساب مشتری"
                            }`}
                            onClick={() => changeActiveStatus(index,item)}
                          ></button>
                          <button
                            className="icon21 border-0 bg-transparent"
                            title="بروزرسانی مشتری"
                            onClick={() => updateCostumer(index)}
                          ></button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
            {showNewRegisterList && (
              <>
                <div class="col-9">
                  {newData && (
                    <>
                      <div className="popup-6 z-1 d-flex rounded-4 p-3 justify-content-between align-items-center">
                        <div className="d-flex justify-content-between">
                          <div class="w-75 position-relative">
                            <div class="d-flex">
                              <label className="user-select-none col-6">
                                جستجو بر اساس
                              </label>
                              <input
                                type="text"
                                value={way}
                                class="rounded-end-2 border-start-0 inp-3 p-1"
                                placeholder="انتخاب کنید"
                              />
                              <button
                                class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-start-0"
                                onClick={() => setShowSearchWay(!showSearchWay)}
                              >
                                <span class="d-inline-block icon30"></span>
                              </button>
                            </div>
                            {showSearchWay && (
                              <div class="d-flex list-margin">
                                <div class="list-group position-absolute rounded-0 list-width">
                                  <div
                                    class="list-group-item list-group-item-action text-start user-select-none"
                                    onClick={() => {
                                      setWay("نام");
                                      setShowSearchWay(false);
                                      setNameSearch(true);
                                      setCodeSearch(false);
                                    }}
                                  >
                                    نام
                                  </div>
                                  <div
                                    class="list-group-item list-group-item-action text-start user-select-none"
                                    onClick={() => {
                                      setWay("کد معاملاتی");
                                      setShowSearchWay(false);
                                      setNameSearch(false);
                                      setCodeSearch(true);
                                    }}
                                  >
                                    کد معاملاتی
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-4"></div>
                          {nameSearch && (
                            <div class="d-flex w-100">
                              <input
                                id="chg"
                                type="text"
                                value={words}
                                class="rounded-end-2 border-start-0 inp-3 p-1 pe-2 farsi-digits"
                                placeholder="بر اساس نام"
                                onChange={(e) => nameSearchHandler(e)}
                                autoComplete="off"
                              />
                              <div class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-end-0">
                                <span class="d-inline-block reportIcon31"></span>
                              </div>
                            </div>
                          )}
                          {codeSearch && (
                            <div class="d-flex w-100">
                              <input
                                id="chg"
                                type="text"
                                value={searchCode}
                                class="rounded-end-2 border-start-0 inp-3 p-1 pe-2 farsi-digits"
                                onChange={(e) => amountSearchHandler(e)}
                                placeholder="بر اساس کد معاملاتی"
                                autoComplete="off"
                              />
                              <div class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-end-0">
                                <span class="d-inline-block reportIcon31"></span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div class="d-flex justify-content-start mb-2">
                          <button
                            class="rgBtn border-0 text-light fw-bold fs-5 mt-2"
                            onClick={() => {
                              setShowNewRegisterList(false);
                              setShowRegister(true);
                            }}
                            title="ثبت دستی کاربر جدید"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {newData && newData.length > 0 ? (
                    <table class="Farsi-Digits table-5 w-100">
                      <tr className="trow">
                        <td className="user-select-none">نام و نام خانوادگی</td>
                        <td className="user-select-none">شماره موبایل</td>
                        <td className="user-select-none">کد معاملاتی</td>
                      </tr>
                      {newData.map((item) => (
                        <tr
                          key={item.id}
                          className={`${item.tel == "" ? "" : "sel"}`}
                          onClick={() => goToRigester(item)}
                          title="ثبت نام نهایی"
                        >
                          <td className="user-select-none">
                            {item.name}
                            {isNameEmpty(item)}
                          </td>
                          <td className="user-select-none">
                            {item.tel}
                            {isPhoneNumberEmpty(item)}
                          </td>
                          <td className="user-select-none">{item.code}</td>
                        </tr>
                      ))}
                    </table>
                  ) : (
                    <>
                      <div className="popup-5 z-1 rounded-4 p-3 user-select-none">
                        مشتری پیش ثبت نام شده ای یافت نشد
                      </div>
                      <div class="d-flex justify-content-end mb-2">
                        <button
                          class="btn-6 rounded border-0 text-light fw-bold px-4 py-2 mt-2 user-select-none"
                          onClick={() => {
                            setShowNewRegisterList(false);
                            setShowRegister(true);
                          }}
                        >
                          ثبت دستی کاربر جدید
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {showRegister && (
              <div class="col-9">
                <form onSubmit={editing ? editHandler : submitHandler}>
                  <div class="box-2 d-flex flex-column p-2">
                    <div class="box-3 d-flex h-100 align-items-center mb-2">
                      <button class="icon22 ms-1"></button>
                      <h1 class="m-0 fs-5 d-inline text-color-1 user-select-none">
                        اطلاعات مشتری
                      </h1>
                    </div>
                    <hr class="hr-1 m-0" />
                    <div class="col-9 p-1 d-flex flex-column mb-2">
                      <div class="w-100">
                        <label
                          class="text-color-1 my-1 user-select-none"
                          for="fln"
                        >
                          نام و نام خانوادگی
                        </label>
                        <input
                          id="fln"
                          type="text"
                          onKeyPress={handleEnterPress}
                          value={selectedUser ? selectedUser.name : fullName}
                          onChange={(e) => nameHandler(e)}
                          class="w-100 rounded-2 inp-3 p-1"
                          autocomplete="off"
                        />
                        {error.name && (
                          <p className="more" style={{ color: "red" }}>
                            {error.name}
                          </p>
                        )}
                      </div>

                      <div class="w-100">
                        <label
                          class="text-color-1 my-1 user-select-none"
                          for="mn"
                        >
                          شماره موبایل
                        </label>
                        <input
                          id="mn"
                          type="text"
                          value={selectedUser ? selectedUser.tel : phoneNumber}
                          autocomplete="off"
                          onKeyPress={handleEnterPress}
                          maxLength={11}
                          onChange={handlePhoneNumberChange}
                          class="w-100 rounded-2 inp-3 p-1 Farsi-Digits text-end"
                          dir="ltr"
                        />
                        {error.phone && (
                          <p className="more" style={{ color: "red" }}>
                            {error.phone}
                          </p>
                        )}
                      </div>

                      <div class="w-100">
                        <label
                          class="text-color-1 my-1 user-select-none"
                          for="cm"
                        >
                          کد معاملاتی
                        </label>
                        <input
                          id="cm"
                          type="text"
                          value={selectedUser ? selectedUser.code : code}
                          onChange={(e) => setCode(e.target.value)}
                          autocomplete="off"
                          onKeyPress={handleEnterPress}
                          class="w-100 rounded-2 inp-3 p-1 Farsi-Digits text-end"
                          dir="ltr"
                          disabled
                        />
                      </div>
                    </div>
                    <hr class="hr-1 m-0" />
                    <div class="box-3 d-flex h-100 align-items-center my-2">
                      <button class="icon23 ms-1"></button>
                      <h1 class="m-0 fs-5 d-inline text-color-1 user-select-none">
                        تنظیمات معادلاتی
                      </h1>
                    </div>
                    <hr class="hr-1 m-0" />
                    <div class="d-flex px-1">
                      <div class="box-4 d-flex align-items-center bg-white rounded-2 p-1 my-2 ms-3">
                        <input
                          id="toggle1"
                          class="check-input"
                          onKeyPress={handleEnterPress}
                          type="checkbox"
                        />
                        <label for="toggle1" class="check-label ms-1"></label>
                        <label
                          for="toggle1"
                          class="text-color-1 user-select-none"
                        >
                          نمایش مانده حساب طلا
                        </label>
                      </div>

                      <div class="box-4 d-flex align-items-center bg-white rounded-2 p-1 my-2">
                        <input
                          id="toggle2"
                          class="check-input"
                          type="checkbox"
                          onKeyPress={handleEnterPress}
                        />
                        <label for="toggle2" class="check-label ms-1"></label>
                        <label
                          for="toggle2"
                          class="text-color-1 user-select-none"
                        >
                          نمایش مانده حساب تومان
                        </label>
                      </div>
                    </div>

                    <div class="d-flex px-1">
                      <div class="box-4 d-flex align-items-center bg-white rounded-2 p-1 my-2 ms-3">
                        <input
                          id="toggle3"
                          onKeyPress={handleEnterPress}
                          class="check-input"
                          type="checkbox"
                        />
                        <label for="toggle3" class="check-label ms-1"></label>
                        <label
                          for="toggle3"
                          class="text-color-1 user-select-none"
                        >
                          نمایش مانده کل به طلا
                        </label>
                      </div>

                      <div class="box-4 d-flex align-items-center bg-white rounded-2 p-1 my-2">
                        <input
                          id="toggle4"
                          class="check-input"
                          type="checkbox"
                          onKeyPress={handleEnterPress}
                        />
                        <label for="toggle4" class="check-label ms-1"></label>
                        <label
                          for="toggle4"
                          class="text-color-1 user-select-none"
                        >
                          نمایش سقف مجاز بدهی
                        </label>
                      </div>
                    </div>

                    <div class="col-9 p-1 d-flex flex-column mb-2">
                      <div class="d-flex">
                        <div class="col-6 ps-3">
                          <label
                            class="text-color-1 my-1  user-select-none"
                            for="hgv"
                          >
                            حداقل وزن{" "}
                            <small className=" user-select-none">(گرم)</small>
                          </label>
                          <input
                            id="hgv"
                            type="text"
                            value={minw}
                            onKeyPress={handleEnterPress}
                            onChange={minwHandler}
                            autocomplete="off"
                            class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                            dir="ltr"
                          />
                          {error.minW && (
                            <p className="more" style={{ color: "red" }}>
                              {error.minW}
                            </p>
                          )}
                        </div>
                        <div class="col-6 pe-3">
                          <label
                            class="text-color-1 my-1  user-select-none"
                            for="hgt"
                          >
                            حداقل تعداد
                          </label>
                          <input
                            id="hgt"
                            type="text"
                            onKeyPress={handleEnterPress}
                            value={minn}
                            onChange={minnHandler}
                            class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                            dir="ltr"
                            autocomplete="off"
                          />
                          {error.minN && (
                            <p className="more" style={{ color: "red" }}>
                              {error.minN}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="col-6 ps-3">
                          <label
                            class="text-color-1 my-1 user-select-none"
                            for="hsv"
                          >
                            حداکثر وزن{" "}
                            <small className=" user-select-none">(گرم)</small>
                          </label>
                          <input
                            id="hsv"
                            type="text"
                            onKeyPress={handleEnterPress}
                            autocomplete="off"
                            value={maxw}
                            onChange={maxwHandler}
                            class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                            dir="ltr"
                          />
                          {error.maxW && (
                            <p className="more" style={{ color: "red" }}>
                              {error.maxW}
                            </p>
                          )}
                        </div>
                        <div class="col-6 pe-3">
                          <label
                            class="text-color-1 my-1 user-select-none"
                            for="hst"
                          >
                            حداکثر تعداد
                          </label>
                          <input
                            id="hst"
                            type="text"
                            value={maxn}
                            onKeyPress={handleEnterPress}
                            autocomplete="off"
                            onChange={maxnHandler}
                            class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                            dir="ltr"
                          />
                          {error.maxN && (
                            <p className="more" style={{ color: "red" }}>
                              {error.maxN}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="col-6 ps-3">
                          <label
                            class="text-color-1 my-1 user-select-none"
                            for="gms"
                          >
                            گروه مشتری
                          </label>
                          <input
                            id="gms"
                            onKeyPress={handleEnterPress}
                            value={selectedUser ? selectedUser.gorooh : groupe}
                            autocomplete="off"
                            onChange={(e) => setGroupe(e.target.value)}
                            type="text"
                            class="w-100 rounded-2 inp-3 p-1"
                          />
                          {error.goroh && (
                            <p className="more" style={{ color: "red" }}>
                              {error.goroh}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="d-flex">
                        <div class="w-100">
                          <div class="d-flex align-items-center">
                            <button class="icon24 ms-1"></button>
                            <label
                              class="text-color-1 my-1 user-select-none"
                              for="mom"
                            >
                              مدیر معاملات
                            </label>
                          </div>
                          <input
                            id="mom"
                            type="text"
                            onKeyPress={handleEnterPress}
                            value={modir}
                            onChange={(e) => modirHandler(e)}
                            class="w-100 rounded-2 inp-3 p-1"
                            autocomplete="off"
                          />
                          {error.modir && (
                            <p className="more" style={{ color: "red" }}>
                              {error.modir}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="w-100">
                        <label
                          class="text-color-1 my-1 user-select-none"
                          for="mek"
                        >
                          میزان اعتبار کاربر بر اساس وزن
                        </label>
                        <input
                          id="mek"
                          type="text"
                          onKeyPress={handleEnterPress}
                          value={wval}
                          autocomplete="off"
                          onChange={wValHandler}
                          class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                          dir="ltr"
                        />
                        {error.wVal && (
                          <p className="more" style={{ color: "red" }}>
                            {error.wVal}
                          </p>
                        )}
                      </div>

                      <div class="w-100">
                        <label
                          class="text-color-1 my-1 user-select-none"
                          for="tkm"
                        >
                          تعداد کاربر مجاز جهت ورود به سیستم
                        </label>
                        <input
                          id="tkm"
                          value={users}
                          onChange={userHandler}
                          type="text"
                          onKeyPress={handleEnterPress}
                          class="w-100 rounded-2 inp-3 p-1 Farsi-Digits"
                          dir="ltr"
                          autocomplete="off"
                        />
                        {error.numOfUser && (
                          <p className="more" style={{ color: "red" }}>
                            {error.numOfUser}
                          </p>
                        )}
                      </div>
                    </div>

                    <div class="d-flex px-1">
                      <div class="box-4 d-flex align-items-center bg-white rounded-2 p-1 my-2 ms-3  user-select-none">
                        <input
                          id="toggle5"
                          onKeyPress={handleEnterPress}
                          class="check-input d-none"
                          type="checkbox"
                        />
                        <label for="toggle5" class="check-label ms-1"></label>
                        <label
                          for="toggle5"
                          class="text-color-1  user-select-none"
                        >
                          نمایش دریافت گزارش ته حساب
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end mb-2">
                    <button
                      class="rounded border-0 bg-white fw-bold px-3 py-2 mt-2 ms-1"
                      onClick={editing ? editExit : exit}
                    >
                      بازگشت
                    </button>
                    {loading ? (
                      <button class="loginButton-2 rounded border-0 text-light fw-bold px-4 py-2 mt-2 align-items-center">
                        <span class="loading user-select-none"></span>
                      </button>
                    ) : (
                      <button
                        class="btn-6 rounded border-0 text-light fw-bold px-4 py-2 mt-2 user-select-none"
                        type="submit"
                      >
                        {editing ? "ذخیره" : "ثبت"}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
}
