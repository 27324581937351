import React, { useEffect, useState } from "react";
import { Toaster, toast } from "sonner";
import { debounce } from "lodash";
import axios from "axios";
import RightNav from "../home_Component/navs/adminRightNav";
import urls from "../../../url";
import "../home_Component/home.css";
export default function HavaleAdmin() {
  const [data, setData] = useState([]);
  const [codeError, setCodeError] = useState("");
  const [secNameReceiver, setSecNameReceiver] = useState("");
  const [anotherNameReceiver, setAnotherNameReceiver] = useState("");
  const [secPhoneReceiver, setSecPhoneReceiver] = useState("");
  const [secCodeReceiver, setSecCodeReceiver] = useState("");
  const [codeReceiver, setCodeReceiver] = useState("");
  const [anotherIndex, setAnotherIndex] = useState("");
  const [searchQueries, setSearchQueries] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [nameReceiver, setNameReceiver] = useState("");
  const [explains, setExplains] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [nameDisable, setNameDisable] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [showSuggList, setShowSuggList] = useState(false);
  const [rejectList, setRejectList] = useState("");
  const [senderName, setSenderName] = useState("");
  const [showRejectList, setShowRejectList] = useState(false);
  const [suggClicked, setSuggClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!explains) {
      setExplains("توضیحی توسط کاربر وارد نشده است");
    }
  }, [showEditPopup]);

    useEffect(() => {
      sendHandler();
    }, []);

  const sendHandler = () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(`${urls.havale}`)
      .then((response) => {
        setData(response.data);
        setSearchQueries(Array(response.data.length).fill(""));
        setSearchResults(Array(response.data.length).fill([]));
      })
      .catch((error) => {
        toast.error("مشکلی در دریافت لیست حواله ها وجود دارد");
      });
  };
  const handleRefresh = (e) => {
    e.preventDefault();
    sendHandler();
  };
  const handleSearchResultClick = (result) => {
    setSearchQueries(result.name);
    setSecNameReceiver(result.name);
    setSecCodeReceiver(result.trades_code);
    setSecPhoneReceiver(result.phone_number);
    setShowSuggList(false);
    setSuggClicked(true);
    setNameDisable(true);
  };
  const takeInfos = async (e) => {
    e.preventDefault();
    if (codeReceiver >= 1) {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${urls.tradeSearch}`,
          {
            trades_code: codeReceiver,
          },
          config
        );
        setSuggClicked(true);
        setSecNameReceiver(response.data[0]);
        setSecPhoneReceiver(response.data[1]);
      } catch (error) {
        setSecNameReceiver("");
        setSecPhoneReceiver("");
        toast.error("لطفا یک کد حسابی معتبر وارد کنید");
      }
    } else {
      setCodeError("لطفا یک کد حسابی معتبر وارد کنید");
    }
  };
  const closeEditPopup = (e) => {
    e.preventDefault();
    setShowEditPopup(false);
    setNameDisable(false);
    setShowSuggList(false);
    setSecCodeReceiver("");
    setSecNameReceiver("");
    setSecPhoneReceiver("");
    setCodeReceiver("");
    setAnotherNameReceiver("");
    setSuggClicked(false);
  };
  const edit = (item, index) => {
    setShowEditPopup(true);
    setAnotherIndex(index);
    setNameReceiver(item.name_reciver);
    setSenderName(item.name_sender);
    setExplains(item.text_field || "توضیحی توسط کاربر وارد نشده است");
  };
  const codeChangeHandler = (e) => {
    setCodeError("");
    const code = e.target.value;
    const regex = /^[0-9]+$/;
    if (regex.test(code)) {
      setCodeReceiver(code);
    } else {
      setCodeReceiver("");
    }
  };
  const notOk = async (index) => {
    try {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${urls.rejectHavale}`,
        {
          id: data[index].id,
          name_reciver: data[index].name_reciver,
          weight: data[index].weight,
          reject_reason: rejectList,
          sender_code_account: data[index].sender_code_account,
        },
        config
      );
      setRejectList("");
      toast.success("حواله با موفقیت رد شد");
    } catch (error) {
      toast.error("مشکلی در رد کردن وجود دارد");
    }
  };
  const debouncedHandleInputChange = debounce(async (e) => {
    const secName = e.target.value;
    if (secName.length >= 1) {
      try {
        const response = await axios.get(
          `${urls.searchHavale}?search=${encodeURIComponent(e.target.value)}`
        );
        setSearchResults(response.data.slice(0, 4));
        setShowSuggList(true);
      } catch (error) {
        toast.error("مشکلی در ارتباط با سرور وجود دارد");
      }
    } else {
      setShowSuggList(false);
    }
  }, 300);

  const handleInputChange = (e) => {
    const secName = e.target.value;
    setAnotherNameReceiver(secName);
    debouncedHandleInputChange(e);
  };
  const ok = async (index) => {
    try {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${urls.havale}`,
        {
          phone_number_reciver: data[index].phone_number_reciver,
          name_reciver: data[index].name_reciver,
          name_sender: data[index].name_sender,
          weight: data[index].weight,
          id: data[index].id,
          reciver_code_account: data[index].reciver_code_account,
          sender_code_account: data[index].sender_code_account,
          second_name_reciver: secNameReceiver,
          text_field: explains,
          second_phone_number_reciver: secPhoneReceiver,
        },
        config
      );
      toast.success("حواله با موفقیت تایید شد");
      setShowEditPopup(false);
      setNameDisable(false);
      setShowSuggList(false);
      setSecCodeReceiver("");
      setSecNameReceiver("");
      setSecPhoneReceiver("");
      setCodeReceiver("");
      setAnotherNameReceiver("");
      setSuggClicked(false);
      sendHandler();
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response.status === 404) {
        toast.error("کاربری با چنین اطلاعاتی یافت نشد");
      } else {
        toast.error("مشکلی در تایید حواله وجود دارد");
      }
    }
  };
  const waitOk = (index) => {
    setSelectedIndex(index);
    setShowPopup(true);
  };
  const waitNotOk = (index) => {
    setSelectedIndex(index);
    setShowRejectPopup(true);
  };
  const handlePopupResponse = async (response) => {
    setShowPopup(false);
    if (response === "yes") {
      await ok(selectedIndex);
      sendHandler();
    }
    setSelectedIndex(null);
  };
  const handleRejectPopupResponse = async (response) => {
    if (rejectList !== "" && response === "yes") {
      await notOk(selectedIndex);
      sendHandler();
      setShowRejectPopup(false);
    } else {
      toast.error("لطفا یک دلیل انتخاب کنید");
    }
    setSelectedIndex(null);
  };
  const waitEdit = async () => {
    if (
      secNameReceiver == "" &&
      (secCodeReceiver == "" || codeReceiver == "")
    ) {
      toast.error("لطفا یکی از راه های جستجو را پر کنید");
    } else if (senderName === secNameReceiver) {
      toast.error(
        "نام و نام خانوادگی فرستنده و نام گیرنده ثانویه نمی‌تواند یکسان باشد"
      );
    } else {
      setLoading(true)
      await ok(anotherIndex);
    }
  };
  return (
    <body className="body">
      {showPopup && (
        <div class="popup-1 z-1 rounded-4 p-3">
          <div>
            <p class="text-center fw-bold user-select-none">
              آیا از ثبت این حواله اطمینان دارید؟
            </p>
          </div>
          <div class="d-flex justify-content-center my-2 w-75">
            <button
              class="border-0 bg-white text-black rounded-2 rounded-end-0 fw-bold py-2 w-25"
              onClick={() => setShowPopup(false)}
            >
              خیر
            </button>
            <button
              class="btn-16 border-0 bgpink text-white rounded-2 rounded-start-0 fw-bold py-2 w-25"
              onClick={() => handlePopupResponse("yes")}
            >
              بله
            </button>
          </div>
        </div>
      )}
      {showEditPopup && (
        <div class="popup-1 z-1 rounded-4 p-3">
          <div class="d-flex w-100 justify-content-end">
            <button class="btn-17 d-flex justify-content-center align-items-center bg-white rounded-2 p-0 border-end-0">
              <span
                class="d-inline-block icon32"
                onClick={closeEditPopup}
              ></span>
            </button>
          </div>

          <label class="mb-1 mt-2 w-100 text-end user-select-none" for="nmg ">
            نام گیرنده اولیه
          </label>
          <input
            id="nmg"
            type="text"
            value={nameReceiver}
            class="rounded-2 inp-3 p-1 w-100"
            disabled
          />

          <label class="mb-1 mt-2 w-100 text-end user-select-none" for="tf ">
            توضیحات فرستنده
          </label>
          <textarea
            id="tf"
            class="rounded-2 p-1 w-100 textarea-2"
            minlength="10"
            value={explains}
            style={{ resize: "none" }}
            maxlength="250"
            disabled
          ></textarea>

          <label class="mb-1 mt-2 w-100 text-end user-select-none" for="chg">
            کد حساب گیرنده ثانویه
          </label>
          <div class="d-flex w-100">
            <input
              id="chg"
              type="text"
              dir="ltr"
              value={nameDisable ? secCodeReceiver : codeReceiver}
              onChange={codeChangeHandler}
              class="col-11 rounded-end-2 border-start-0 inp-3 p-1 pe-0 farsi-digits"
              autoComplete="off"
              disabled={suggClicked}
            />
            <button
              class="col-1 btn-17 d-flex justify-content-center align-items-center bg-white rounded-start-2 p-0 border-end-0"
              disabled={suggClicked}
              onClick={takeInfos}
            >
              <span class="d-inline-block icon31" onClick={takeInfos}></span>
            </button>
          </div>
          {codeError && (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "12px",
              }}
            >
              {codeError}
            </p>
          )}
          <label class="mb-1 mt-2 w-100 text-end user-select-none" for="ngs">
            نام گیرنده ثانویه
          </label>
          <input
            id="ngs"
            type="text"
            value={suggClicked ? secNameReceiver : anotherNameReceiver}
            onChange={handleInputChange}
            class="rounded-2 inp-3 p-1 w-100"
            disabled={suggClicked}
            autoComplete="off"
          />
          {showSuggList && (
            <div className="d-flex justify-content-center">
              <div className="list-group position-absolute mt-1 rounded-0">
                {searchResults.map((result) => (
                  <div
                    key={result.id}
                    className="list-group-item list-group-item-action text-start"
                    onClick={() => handleSearchResultClick(result)}
                  >
                    {result.name}
                  </div>
                ))}
              </div>
            </div>
          )}

          <label class="mb-1 mt-2 w-100 text-end user-select-none" for="st">
            شماره تلفن
          </label>
          <input
            id="st"
            type="text"
            value={secPhoneReceiver}
            dir="ltr"
            class="rounded-2 inp-3 p-1 w-100 farsi-digits"
            autoComplete="off"
            disabled
          />
          <div class="d-flex justify-content-center mt-4 mb-2 w-100">
            <button
              class="border-0 bg-white text-black rounded-2 rounded-start-0 fw-bold py-2 w-25"
              onClick={closeEditPopup}
            >
              انصراف
            </button>
            {loading ? (
              <button class="loginButton-2 rounded border-0 text-light fw-bold w-25 py-2 mt-2 align-items-center">
                <span class="loading"></span>
              </button>
            ) : (
              <button
                class="btn-16 border-0 finalbtn text-white rounded-2 rounded-end-0 fw-bold py-2 w-25"
                onClick={waitEdit}
              >
                تایید
              </button>
            )}
          </div>
        </div>
      )}
      {showRejectPopup && (
        <div class="popup-1 z-1 rounded-4 p-3">
          <div>
            <p class="text-center fw-bold user-select-none">
              لطفا دلیل رد کردن خود را انتخاب کنید.
            </p>
          </div>

          <div class="w-75 position-relative">
            <div class="d-flex">
              <input
                type="text"
                value={rejectList}
                class=" col-10 rounded-end-2 border-start-0 inp-3 p-1 pe-0"
                placeholder="انتخاب کنید"
              />
              <button
                onClick={() => setShowRejectList(!showRejectList)}
                class="btn-17 d-flex justify-content-center col-2 align-items-center bg-white rounded-start-2 p-0 border-start-0"
              >
                <span class="d-inline-block icon30"></span>
              </button>
            </div>
            {showRejectList && (
              <div class="d-flex">
                <div class="list-group position-absolute rounded-0  w-100">
                  <div
                    class="list-group-item list-group-item-action text-start"
                    onClick={(e) => {
                      setRejectList("کمبود اعتبار طلایی");
                      setShowRejectList(false);
                    }}
                  >
                    کمبود اعتبار طلایی
                  </div>
                  <div
                    class="list-group-item list-group-item-action text-start"
                    onClick={(e) => {
                      setRejectList("بدهکاری مالی");
                      setShowRejectList(false);
                    }}
                  >
                    بدهکاری مالی
                  </div>
                  <div
                    class="list-group-item list-group-item-action text-start"
                    onClick={(e) => {
                      setRejectList("پرداخت از حساب مشتری");
                      setShowRejectList(false);
                    }}
                  >
                    پرداخت از حساب مشتری
                  </div>
                  <div
                    class="list-group-item list-group-item-action text-start"
                    onClick={(e) => {
                      setRejectList("توضیحات ناقص");
                      setShowRejectList(false);
                    }}
                  >
                    توضیحات ناقص
                  </div>
                </div>
              </div>
            )}
          </div>

          <div class="d-flex justify-content-center my-2 w-100">
            <button
              class="border-0 bg-white text-black rounded-2 rounded-end-0 fw-bold py-2 w-25"
              onClick={() => {
                setShowRejectPopup(false);
                setRejectList("");
              }}
            >
              انصراف
            </button>
            <button
              class="btn-16 border-0 bgpink text-white rounded-2 rounded-start-0 fw-bold py-2 w-25"
              onClick={() => handleRejectPopupResponse("yes")}
            >
              تایید
            </button>
          </div>
        </div>
      )}
      <div
        className={`container-fluid ${
          showPopup || showRejectPopup || showEditPopup ? "blur-1" : ""
        } cHeight d-flex p-0`}
      >
        <RightNav />
        <div class="col-10 px-1 pt-3 overflow-auto" dir="ltr">
          <Toaster
            richColors
            position="top-center"
            dir="rtl"
            visibleToasts={1}
            closeButton
          />
          <div class="d-flex flex-column" dir="rtl">
            <div class="col-10 px-4 d-flex pt-3 flex-column">
              <div class="d-flex text-black mt-4">
                <div class="homebtn-1 p-2 rounded-top-3">
                  <button class="border-0 bg-transparent">لیست حواله‌ها</button>
                </div>
                <div class="btn-5 homebtn-1 p-2 d-flex align-items-center">
                  <button
                    class="border-0 bg-transparent p-0"
                    onClick={handleRefresh}
                  >
                    بروزرسانی
                  </button>
                  <button
                    class="icon21 border-0 bg-transparent"
                    onClick={handleRefresh}
                  ></button>
                </div>
              </div>
              {data.length >= 1 ? (
                <table class="Farsi-Digits table-6 w-100">
                  <tr class="trow">
                    <td className="user-select-none">تاریخ</td>
                    <td className="user-select-none">
                      نام و نام خانوادگی <small className="user-select-none">(فرستنده)</small>
                    </td>
                    <td className="user-select-none">
                      نام و نام خانوادگی <small className="user-select-none">(گیرنده)</small>
                    </td>
                    <td className="user-select-none" style={{ width: 9 + "%" }}>کد حساب</td>
                    <td className="user-select-none">شماره موبایل</td>
                    <td className="user-select-none">وزن</td>
                    <td className="user-select-none" style={{ width: 13 + "%" }}>عملیات‌ها</td>
                  </tr>
                  {data.map((item, index) => (
                    <tr key={item.id}>
                      <td className="user-select-none">
                        {item.jalali_date}-{item.tarikh}
                      </td>
                      <td className="user-select-none">{item.name_sender}</td>
                      <td className="user-select-none">{data[index].name_reciver}</td>
                      <td className="user-select-none">{item.reciver_code_account}</td>
                      <td className="user-select-none">{item.phone_number_reciver}</td>
                      <td className="user-select-none">{item.weight} گرم</td>
                      <td class="d-flex align-items-center justify-content-around">
                        <button
                          className="cIcon-havale-2 icon15-havale col-4"
                          onClick={() => edit(item, index)}
                        ></button>
                        <button
                          class={`cIcon-2 icon17 disable col-4`}
                          onClick={() => waitOk(index)}
                        ></button>
                        <button
                          class={`cIcon-2 icon18 disable col-4`}
                          onClick={() => waitNotOk(index)}
                        ></button>
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <div className="popup-5 z-1 rounded-4 p-3 user-select-none">
                  حواله ای یافت نشد
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
