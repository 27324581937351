import React, { useState, useEffect } from "react";
import { Toaster,toast } from "sonner";
import AdminHome from "../admin_components/home_Component/Home";
import UserHome from "../user_Components/userhome_Component/user_home";
import TraderHome from "../trader_Components/home_Component/traderHome"
import urls from "../../url"
export default function Home() {
  const [role, setRole] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;
      if (!token) {
        handleUnauthorized();
        return;
      }

      try {
        const response = await fetch(`${urls.checkToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          handleUnauthorized();
        }
      } catch (error) {
        
      }
    };

    fetchData();
  }, []);
  const handleUnauthorized = () => {
    toast.error("مهلت استفاده شما از سامانه به پایان رسید لطفا دوباره ورود کنید");
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("havaleList");
    window.location.href = "/login";
  };

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("userRole"));
    if (localStorageData) {
      const roleOfUser = localStorageData.userRole;
      setRole(roleOfUser);
    }
  }, []);
  const roleComponents = {
    admin: <AdminHome />,
    user: <UserHome />,
    admin_trade: <TraderHome />
  };

  return <div>
  <Toaster richColors position="top-center" dir="rtl" visibleToasts={1} closeButton />
  <div>{roleComponents[role] || <p>Loading...</p>}</div>
  </div>;
}