import React, { useState, useEffect, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./login.css";
import AuthContext from "../../context/authContext";
import eyeOff from "../../asset/publicAssest/visibility_off.svg";
import eyeOn from "../../asset/publicAssest/visibility_on.svg";
import LoginYup from "./loginYup";
import secFgPassYup from "../fgpass_Components/secFgPassYup";
import { Navigate } from "react-router-dom";
import urls from "../../url";
import axios from "axios";
const Login = () => {
  const randString = parseInt(Math.floor(1000 + Math.random() * 9000));
  const secRandString = parseInt(Math.floor(10000 + Math.random() * 90000));
  const [showLogin, setShowLogin] = useState(true);
  const [phoneInput, setPhoneInput] = useState("");
  const [secPhoneInput, setSecPhoneInput] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [firstCaptcha, setFirstCaptcha] = useState(randString);
  const [secondCaptcha, setSecondCaptcha] = useState(secRandString);
  const [anotherSecondCaptcha, setAnotherSecondCaptcha] =
    useState(secRandString);
  const [smsPopup, setSmsPopup] = useState(false);
  const [capText, setCapText] = useState("");
  const [smsText, setSmsText] = useState("");
  const [goToFg, setGoToFg] = useState(false);
  const [editDisable, setEditDisable] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [remClicked, setRemClicked] = useState(false);
  const [userInfos, setUserInfos] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [goToLogin, setGoToLogin] = useState(false);
  const [goToHome, setGoToHome] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSmsSent, setIsSmsSent] = useState(false);
  useEffect(() => {
    if (isValid) {
      if (firstLogin) {
        setShowReset(true);
        setSmsPopup(false);
        setFirstCaptcha(parseInt(Math.floor(1000 + Math.random() * 9000)));
        setCapText("");
        setLoading(false);
      } else {
        setGoToHome(true);
        setLoading(false);
      }
    }
  }, [isValid]);
  useEffect(() => {
    localHistory();

    let timer;
    if (timerRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setTimerRunning(false);
    }
    return () => clearInterval(timer);
  }, [timerRunning, timeLeft]);
  const localHistory = () => {
    const localHistoryPhone = localStorage.getItem("PhoneNumber");
    setPhoneInput(localHistoryPhone);
    const localHistoryPass = localStorage.getItem("password");
    setPassword(localHistoryPass);
  };
  const handleToggleConfirmPassword = (event) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleConfirmPassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setConfirmPassword(newWords);
    } else {
      setConfirmPassword(words);
      setError({});
    }
  };
  const handleNewPassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setNewPassword(newWords);
    } else {
      setNewPassword(words);
      setError({});
    }
  };
  const secSubmitHandler = async (event) => {
    event.preventDefault();
    const passYup = {
      nPass: event.target[0].value,
      cPass: event.target[2].value,
    };
    try {
      const isValidYUP = await secFgPassYup.validate(passYup, {
        abortEarly: false,
      });
      if (isValidYUP) {
        const letterRegex = /[a-z]/;
        const capitalRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const hasLetter = letterRegex.test(newPassword);
        const hasCapital = capitalRegex.test(newPassword);
        const hasNumber = numberRegex.test(newPassword);

        if (!hasCapital || !hasNumber || !hasLetter) {
          toast.error(
            "رمز عبور باید حداقل یک حرف بزرگ و کوچک و یک عدد داشته باشد."
          );
        } else if (newPassword !== confirmPassword) {
          toast.error("تکرار رمز جدید با رمز جدید یکسان نیست");
        } else {
          sendResetedPass();
          setLoading(true);
        }
      }
    } catch (err) {
      let errors = err.inner
        ? err.inner.reduce(
            (acc, err) => ({
              ...acc,
              [err.path]: err.message,
            }),
            {}
          )
        : {};
      setError(errors);
    }
  };
  const sendResetedPass = async () => {
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${urls.resetPass}`,
        {
          new_password: newPassword,
          confirm_new_password: confirmPassword,
        },
        config
      );
      toast.success("رمز با موفقیت تغییر کرد");
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error("مشکلی در ثبت اطلاعات وجود دارد");
      setLoading(false);
    }
  };
  const authContext = useContext(AuthContext);
  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) || newValue === "") {
      setPhoneInput(newValue);
    } else {
      toast.error("لطفاشماره تلفن را با ۰۹ شروع کرده و از اعداد استفاده کنید");
    }
  };

  const resetTimer = () => {
    const phoneSms = localStorage.getItem("phoneForSms");
    setSecPhoneInput(phoneSms);
    setTimeLeft(120);
    setTimerRunning(true);
    setEditDisable(true);
    setAnotherSecondCaptcha(
      parseInt(Math.floor(10000 + Math.random() * 90000))
    );
    anotherSmsVal();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handlePassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setPassword(newWords);
    } else {
      setPassword(words);
    }
  };
  const refCaptcha = (event) => {
    event.preventDefault();
    setFirstCaptcha(parseInt(Math.floor(1000 + Math.random() * 9000)));
  };
  const handleCodeChange = (e) => {
    const capCode = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(capCode)) {
      setCapText(capCode);
    }
  };
  const smsVal = async () => {
    try {
      const response = await fetch(
        `https://api.kavenegar.com/v1/74663543567649323755774D644845683857672F76556262705663704D54424F665A2B4C6A617A614A6E633D/verify/lookup.json?receptor=${phoneInput}&token=${secondCaptcha}&template=ramzyekbarmasraf`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setTimeLeft(60);
      setTimerRunning(true);
      toast.success("کد با موفقیت ارسال شد");
    } catch (error) {
      toast.error("مشکلی در ارسال کد وجود دارد");
    }
  };
  const anotherSmsVal = async () => {
    try {
      const response = await fetch(
        `https://api.kavenegar.com/v1/74663543567649323755774D644845683857672F76556262705663704D54424F665A2B4C6A617A614A6E633D/verify/lookup.json?receptor=${localStorage.getItem(
          "phoneForSms"
        )}&token=${secondCaptcha}&template=ramzyekbarmasraf`,
        {
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toast.success("کد با موفقیت ارسال شد");
    } catch (error) {
      toast.error("مشکلی در ارسال کد وجود دارد");
    }
  };
  const remHandler = () => {
    setRemClicked(!remClicked);
    console.log(remClicked);
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isSmsSent) {
      setFirstCaptcha(parseInt(Math.floor(1000 + Math.random() * 9000)));
      setCapText("");
      const userYup = {
        phone: event.target[0].value,
        pass: event.target[2].value,
        cap: event.target[5].value,
      };
      try {
        const isValidYUP = await LoginYup.validate(userYup, {
          abortEarly: false,
        });
        if (isValidYUP) {
          if (parseInt(capText) !== firstCaptcha) {
            toast.error("لطفا کد را به درستی وارد کنید");
          } else {
            secVal(event);
            setLoading(true);
          }
        }
      } catch (err) {
        let errors = err.inner
          ? err.inner.reduce(
              (acc, err) => ({
                ...acc,
                [err.path]: err.message,
              }),
              {}
            )
          : {};
        setError(errors);
        console.log(error);
      }
    }
  };

  const secVal = (event) => {
    event.preventDefault();
    if (error.phone || error.pass || error.cap) {
      toast.error("لطفا اطلاعات خود را به درستی وارد کنید");
      setLoading(false);
    } else {
      sendInfosToApi();
    }
    if (remClicked == true) {
      localStorage.setItem("PhoneNumber", phoneInput);
      localStorage.setItem("password", password);
    }
    localStorage.setItem("phoneForSms", phoneInput);
  };
  const sendInfosToApi = async () => {
    const formData = new FormData();
    formData.append("password", password.toString());
    formData.append("phone_number", phoneInput.toString());

    try {
      const response = await fetch(`${urls.loginUrl}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const trueStatusCode = response.status;
        setUserInfos(await response.json());
        setLoading(false);
        setShowLogin(false);
        smsVal();
        setSmsPopup(true);
        if (trueStatusCode === 200) {
          setFirstLogin(false);
        } else if (trueStatusCode === 202) {
          setFirstLogin(true);
        }
      } else {
        const falseStatusCode = response.status;
        if (falseStatusCode === 404) {
          throw new Error("کاربری با چنین اطلاعاتی یافت نشد");
        } else if (falseStatusCode === 400) {
          throw new Error("کاربری با چنین اطلاعاتی یافت نشد");
        } else if (falseStatusCode === 403) {
          throw new Error("حساب کاربر غیر فعال است");
        } else {
          throw new Error("مشکلی در ورود وجود دارد");
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  const smsChangeHandler = (e) => {
    const smsCode = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(smsCode)) {
      setSmsText(smsCode);
    }
  };
  const editInfos = () => {
    setShowLogin(true);
    setSmsPopup(false);
    setIsSmsSent(false);
    setTimeLeft(0);
    setSmsText("");
  };
  const loginVal = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (parseInt(smsText) !== parseInt(secondCaptcha)) {
      toast.error("کد وارد شده اشتباه است");
      setLoading(false);
    } else {
      setIsValid(true);
      setIsSmsSent(true);
      localStorage.removeItem("phoneForSms");
    }
    const result = userInfos;
    authContext.login(result[2], result[1], result[0]);
  };
  if (goToLogin) {
    return <Navigate to="/login" />;
  }
  if (goToHome) {
    return <Navigate to="/" />;
  }
  if (goToFg) {
    return <Navigate to="/forgotpass" />;
  }
  return (
    <div className="body">
      <div className="container-fluid d-flex justify-content-center loginHeight100 align-items-center">
        <div className="row col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
          <Toaster
            richColors
            position="top-center"
            dir="rtl"
            visibleToasts={1}
            closeButton
          />
          {smsPopup && (
            <div className="loginpopup">
              <div className="d-flex justify-content-center">
                <div className="col-7">
                  <div className="d-flex justify-content-start">
                    <label
                      htmlFor="tPassword"
                      className="mb-1 mt-4 user-select-none"
                    >
                      رمز پیامکی
                    </label>
                  </div>
                  <input
                    dir="ltr"
                    id="tPassword"
                    className="lgInputs Farsi-Digits rounded ps-2 text-start pHolder w-100"
                    type="text"
                    autocomplete="off"
                    value={smsText}
                    onChange={smsChangeHandler}
                    maxLength={5}
                    placeholder="رمز عبور پیامک شده را وارد کنید"
                  />
                </div>
              </div>
              {timeLeft <= 0 ? (
                <div className="d-flex justify-content-center">
                  <div className="col-4  Farsi-Digits">
                    <p
                      className="secCustomSc cPointer w-100 h-100 user-select-none"
                      onClick={resetTimer}
                    >
                      ارسال کد
                    </p>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="col-4  Farsi-Digits">
                    <p className="secCustomSc w-100 h-100 user-select-none">
                      {formatTime(timeLeft)}
                    </p>
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-center">
                {loading ? (
                  <button class="loginButton-2 rounded border-0 text-light fw-bold px-5 py-2 mt-3 align-items-center">
                    <span class="loading user-select-none"></span>
                  </button>
                ) : (
                  <button
                    className="rounded-3 border-0 user-select-none"
                    onClick={loginVal}
                  >
                    ورود
                  </button>
                )}
              </div>

              <div onClick={editInfos}>
                <p className="text-center login-p user-select-none">
                  {" "}
                  ویرایش اطلاعات
                </p>
              </div>
            </div>
          )}
          {showLogin && (
            <div className="loginWrapper rounded-4 px-0 px-sm-4 py-4">
              <div
                className="row col-10 offset-1"
                style={{ marginRight: "35px" }}
              >
                <form
                  onSubmit={submitHandler}
                  className="d-flex flex-column"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <h1 className="fs-4 fw-bold user-select-none">
                    ورود به سایت
                  </h1>
                  <label
                    htmlFor="pNumber"
                    className="mb-1 mt-3 user-select-none"
                  >
                    شماره موبایل
                  </label>

                  <input
                    id="pNumber"
                    name="phone"
                    className="lgInputs rounded ps-2 Farsi-Digits pHolder"
                    type="text"
                    dir="ltr"
                    value={phoneInput}
                    maxLength={11}
                    onChange={handlePhoneNumberChange}
                    placeholder="شماره موبایل خود را وارد کنید (۱۱رقمی)"
                    autoComplete="off"
                  />
                  <div>
                    {error.phone && (
                      <p className="more" style={{ color: "red" }}>
                        {error.phone}
                      </p>
                    )}
                  </div>

                  <div className="d-flex align-items-baseline">
                    <div>
                      <label className="mb-1 mt-4 user-select-none">
                        رمز عبور
                      </label>
                    </div>

                    <div className="d-flex me-auto">
                      <button
                        className="editB1 editB2 user-select-none"
                        onClick={() => setGoToFg(true)}
                      >
                        رمز عبور خود را فراموش کرده‌اید؟
                      </button>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="col-11">
                      <input
                        id="password"
                        name="pass"
                        className="lgInputs rounded-end w-100 pHolder"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePassChange}
                        dir="ltr"
                        autocomplete="off"
                        maxLength={14}
                        placeholder="رمز عبور خود را وارد کنید"
                      />
                      <div>
                        {error && error.pass && (
                          <p className="more" style={{ color: "red" }}>
                            {error.pass}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-1 rounded-start loginEditDiv d-flex justify-content-center align-items-center">
                      {password && password.length > 0 && (
                        <button className="editB1">
                          <img
                            onClick={handleTogglePassword}
                            src={showPassword ? eyeOff : eyeOn}
                            alt=""
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <div>
                      <label
                        htmlFor="password"
                        className="mb-1 mt-4 user-select-none"
                      >
                        کد امنیتی تصویر
                      </label>
                    </div>

                    <div className="d-flex me-auto">
                      <button
                        className="editB1 editB2 user-select-none"
                        onClick={refCaptcha}
                      >
                        تغییر کد
                      </button>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-8 ms-1">
                      <input
                        dir="ltr"
                        id="iSCode"
                        name="cap"
                        className="lgInputs Farsi-Digits rounded ps-2 text-start w-100 pHolder"
                        placeholder="کد امنیتی را وارد کنید"
                        type="text"
                        value={capText}
                        autocomplete="off"
                        onChange={handleCodeChange}
                        maxLength={4}
                      />
                    </div>
                    <div className="col-4 CustomSc Farsi-Digits">
                      {firstCaptcha}
                    </div>
                  </div>
                  <div>
                    {error.cap && (
                      <p className="more" style={{ color: "red" }}>
                        {error.cap}
                      </p>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      id="rememberMe"
                      type="checkbox"
                      className="lgInputs cPointer"
                      onChange={remHandler}
                    />
                    <label
                      htmlFor="rememberMe"
                      className="fs13 me-1 user-select-none"
                    >
                      من را به خاطر بسپار
                    </label>
                  </div>
                  <div className="d-flex justify-content-center">
                    {loading ? (
                      <button class="loginButton-2 rounded border-0 text-light fw-bold px-5 py-2 mt-3 align-items-center">
                        <span class="loading user-select-none"></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="loginButton rounded border-0 text-light fw-bold px-5 py-2 mt-3 user-select-none"
                      >
                        ورود
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
          {showReset && (
            <div class="loginWrapper rounded-4 px-0 px-sm-4 py-4">
              <div
                className="row col-10 offset-1"
                style={{ marginRight: "35px" }}
              >
                <form
                  onSubmit={secSubmitHandler}
                  class="d-flex flex-column p-0"
                >
                  <h1 class="fs-4 fw-bold user-select-none">خوش آمدید</h1>
                  <p className="user-select-none">
                    برای حفظ امنیت حساب کاربری رمز خود را عوض کنید
                  </p>
                  <p className="more Farsi-Digits fontSize user-select-none">
                    رمز عبور جدید باید بین 8 تا 12 کاراکتر باشد و از ترکیب
                    اعداد، حروف بزرگ و کوچک تشکیل شود
                  </p>
                  <div>
                    <label for="nP" class="mb-1 mt-4 user-select-none">
                      رمز عبور جدید
                    </label>
                    <div class="d-flex">
                      <div class="col-11">
                        <input
                          id="nP"
                          class="lgInputs rounded-end w-100 pHolder"
                          type={showPassword ? "text" : "password"}
                          onChange={handleNewPassChange}
                          dir="ltr"
                          autocomplete="off"
                          maxLength={12}
                          placeholder="رمز عبور جدید خود را وارد کنید"
                        />
                        {error.nPass && (
                          <p className="more" style={{ color: "red" }}>
                            {error.nPass}
                          </p>
                        )}
                      </div>

                      <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                        {newPassword.length > 0 && (
                          <button className="editB1">
                            <img
                              onClick={handleTogglePassword}
                              src={showPassword ? eyeOff : eyeOn}
                              alt=""
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label for="rnp" class="mb-1 mt-4 user-select-none">
                      تکرار رمز عبور جدید
                    </label>
                    <div class="d-flex">
                      <div class="col-11">
                        <input
                          id="rnp"
                          autocomplete="off"
                          class="lgInputs rounded-end w-100 pHolder"
                          type={showConfirmPassword ? "text" : "password"}
                          onChange={handleConfirmPassChange}
                          dir="ltr"
                          maxLength={12}
                          placeholder="رمز عبور جدید خود را تکرار کنید"
                        />
                        {error.cPass && (
                          <p className="more" style={{ color: "red" }}>
                            {error.cPass}
                          </p>
                        )}
                      </div>
                      <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                        {confirmPassword.length > 0 && (
                          <button className="editB1">
                            <img
                              onClick={handleToggleConfirmPassword}
                              src={showConfirmPassword ? eyeOff : eyeOn}
                              alt=""
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    {loading ? (
                      <button class="loginButton-2 rounded border-0 text-light fw-bold px-5 py-2 mt-3 align-items-center">
                        <span class="loading user-select-none"></span>
                      </button>
                    ) : (
                      <button
                        class="bColor rounded border-0 text-light fw-bold px-5 py-2 custom-mt user-select-none"
                        type="submit"
                      >
                        تایید
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Login;
