import React, { useState, useEffect } from "react";
import { Toaster,toast } from "sonner";
import HavaleAdmin from "../admin_components/havale_admin/havaleAdmin";
import UserHavale from "../user_Components/havale_user/userHavale";
import urls from "../../url"
export default function Havale() {
  const [role, setRole] = useState("");
  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("userRole"));
    if (localStorageData) {
      const roleOfUser = localStorageData.userRole;
      setRole(roleOfUser);
    }
  }, []);
  console.log("yo", role);
  const roleComponents = {
    admin: <HavaleAdmin />,
    user: <UserHavale />,
    admin_trade: <HavaleAdmin />
  };
  return <div>
    <Toaster richColors position="top-center" dir="rtl" visibleToasts={1} closeButton />
    <div>{roleComponents[role] || <p>Loading...</p>}</div>
    </div>;
}