import React, { useState, useEffect } from "react";
import { Toaster, toast } from "sonner";
import { Navigate } from "react-router-dom";
import "../user_Components/userhome_Component/userhome.css";
import Rnav from "../user_Components/userhome_Component/user_home_navs/userRightNav";
import eyeOff from "../../asset/publicAssest/visibility_off.svg";
import eyeOn from "../../asset/publicAssest/visibility_on.svg";
import fgPassYup from "../fgpass_Components/fgpassYup";
import axios from "axios";
import urls from "../../url";
import RightNav from "../admin_components/home_Component/navs/adminRightNav";
import RtNav from "../trader_Components/home_Component/navs/adminRightNav";
export default function ResetPass() {
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [currentPassErrorMsg, setCurrentPassErrorMsg] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [goToLogin, setGoToLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [role, setRole] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).token
        : null;
      if (!token) {
        handleUnauthorized();
        return;
      }

      try {
        const response = await fetch(`${urls.checkToken}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          handleUnauthorized();
        }
      } catch (error) {}
    };

    fetchData();
  }, []);
  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("userRole"));
    if (localStorageData) {
      const roleOfUser = localStorageData.userRole;
      setRole(roleOfUser);
    }
  }, []);
  const roleComponents = {
    admin: <RightNav />,
    user: <Rnav />,
    admin_trade: <RtNav />,
  };
  const handleUnauthorized = () => {
    toast.error(
      "مهلت استفاده شما از سامانه به پایان رسید لطفا دوباره ورود کنید"
    );
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("havaleList");
    window.location.href = "/login";
  };
  const secSubmitHandler = async (event) => {
    event.preventDefault();
    const passYup = {
      uPass: event.target[0].value,
      nPass: event.target[3].value,
      cPass: event.target[6].value,
    };
    try {
      const isValidYUP = await fgPassYup.validate(passYup, {
        abortEarly: false,
      });
      if (isValidYUP) {
        const letterRegex = /[a-z]/;
        const capitalRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const hasLetter = letterRegex.test(newPassword);
        const hasCapital = capitalRegex.test(newPassword);
        const hasNumber = numberRegex.test(newPassword);

        if (!hasCapital || !hasNumber || !hasLetter) {
          setPassErrorMsg(
            "رمز عبور باید حداقل یک حرف بزرگ و کوچک و یک عدد داشته باشد."
          );
        } else if (newPassword !== confirmPassword) {
          setConfirmPasswordErrorMsg("تکرار رمز جدید با رمز جدید یکسان نیست");
        } else {
          sendResetedPass();
        }
      }
    } catch (err) {
      let errors = err.inner
        ? err.inner.reduce(
            (acc, err) => ({
              ...acc,
              [err.path]: err.message,
            }),
            {}
          )
        : {};
      setError(errors);
      console.log(error);
    }
  };
  const handlCurrentPassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setCurrentPassword(newWords);
    } else {
      setCurrentPassword(words);
      setCurrentPassErrorMsg("");
      setError({});
    }
  };
  const handleNewPassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setNewPassword(newWords);
    } else {
      setNewPassword(words);
      setPassErrorMsg("");
      setError({});
    }
  };
  const handleToggleCurrentPassword = (event) => {
    event.preventDefault();
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleConfirmPassChange = (e) => {
    const words = e.target.value;
    const regex = /[پچجحخهعغفقثصضشسیبلاتنمکگوئدذرزطظژؤإأءًٌٍَُِّ\s]+$/;
    if (regex.test(words)) {
      toast.error("لطفا از حروف فارسی استفاده نکنید");
      const newWords = words.replace(regex, "");
      setConfirmPassword(newWords);
    } else {
      setConfirmPassword(words);
      setConfirmPasswordErrorMsg("");
      setError({});
    }
  };
  const handleToggleConfirmPassword = (event) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const sendResetedPass = async () => {
    setLoading(true);
    const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).token
      : null;
  
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await fetch(`${urls.secResetPass}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          password: currentPassword,
          new_password: newPassword,
          confirm_new_password: confirmPassword,
        }),
      });
  
      if (response.status === 200) {
        toast.success("رمز با موفقیت تغییر کرد برای امنیت بیشتر دوباره وارد شوید");
        setTimeout(() => {
          setGoToLogin(true);
        }, 3000);
        setLoading(false);
      } else if (response.status === 403) {
        throw new Error("رمز فعلی اشتباه وارد شده است");
      } else {
        throw new Error("مشکلی در تغییر رمز وجود دارد");
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  if (goToLogin) {
    return <Navigate to="/login" />;
  }
  return (
    <body class="body">
      <div class="container-fluid cHeight d-flex p-0">
        <div className="col-2">{roleComponents[role]}</div>
        <div class="col-9 d-flex">
          <div class="col-10 px-4 d-flex pt-3 flex-column">
            <Toaster
              richColors
              position="top-center"
              dir="rtl"
              visibleToasts={1}
              closeButton
            />
            <div class="d-flex text-black">
              <div class="resetMargin"></div>
            </div>
            <form
              onSubmit={secSubmitHandler}
              class="d-flex resetForm col-9 flex-column"
            >
              <p class="d-block text-black mb-5 user-select-none">
                رمز عبور جدید باید بین 8 تا 12 کاراکتر باشد و از ترکیب اعداد،
                حروف بزرگ و کوچک تشکیل شود.
              </p>
              <div>
                <label for="nP" class="d-block text-black mb-1 user-select-none">
                  رمز عبور فعلی
                </label>
                <div class="d-flex">
                  <div class="col-11">
                    <input
                      id="nP"
                      class="rpInputs rounded-end w-100 pHolder"
                      value={currentPassword}
                      type={showCurrentPassword ? "text" : "password"}
                      onChange={(e) => handlCurrentPassChange(e)}
                      dir="ltr"
                      autocomplete="off"
                      maxLength={12}
                      placeholder="رمز عبور فعلی خود را وارد کنید"
                    />
                    {currentPassErrorMsg && (
                      <div>
                        <p className="more" style={{ color: "red" }}>
                          {currentPassErrorMsg}
                        </p>
                        <br />
                      </div>
                    )}

                    {error.uPass && (
                      <p className="more" style={{ color: "red" }}>
                        {error.uPass}
                      </p>
                    )}
                  </div>

                  <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                    <button class="editB1">
                      {currentPassword.length > 0 && (
                        <button className="editB1">
                          <img
                            onClick={handleToggleCurrentPassword}
                            src={showCurrentPassword ? eyeOff : eyeOn}
                            alt=""
                          />
                        </button>
                      )}
                    </button>
                  </div>
                </div>
                <label for="nP" class="d-block text-black mb-1 user-select-none">
                  رمز عبور جدید
                </label>
                <div class="d-flex">
                  <div class="col-11">
                    <input
                      id="nP"
                      class="rpInputs rounded-end w-100 pHolder"
                      value={newPassword}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => handleNewPassChange(e)}
                      dir="ltr"
                      autocomplete="off"
                      maxLength={12}
                      placeholder="رمز عبور جدید خود را وارد کنید"
                    />
                    {passErrorMsg && (
                      <div>
                        <p className="more" style={{ color: "red" }}>
                          {passErrorMsg}
                        </p>
                        <br />
                      </div>
                    )}

                    {error.nPass && (
                      <p className="more" style={{ color: "red" }}>
                        {error.nPass}
                      </p>
                    )}
                  </div>

                  <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                    <button class="editB1">
                      {newPassword.length > 0 && (
                        <button className="editB1">
                          <img
                            onClick={handleTogglePassword}
                            src={showPassword ? eyeOff : eyeOn}
                            alt=""
                          />
                        </button>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <label for="rnp" class="d-block text-black mb-1 user-select-none">
                  تکرار رمز عبور جدید
                </label>
                <div class="d-flex">
                  <div class="col-11">
                    <input
                      id="rnp"
                      autocomplete="off"
                      class="rpInputs rounded-end w-100 pHolder"
                      value={confirmPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={(e) => handleConfirmPassChange(e)}
                      dir="ltr"
                      maxLength={12}
                      placeholder="رمز عبور جدید خود را تکرار کنید"
                    />
                    {confirmPasswordErrorMsg && (
                      <div>
                        <p className="more" style={{ color: "red" }}>
                          {confirmPasswordErrorMsg}
                        </p>
                        <br />
                      </div>
                    )}

                    {error.cPass && (
                      <p className="more" style={{ color: "red" }}>
                        {error.cPass}
                      </p>
                    )}
                  </div>
                  <div class="col-1 rounded-start editDiv d-flex justify-content-center align-items-center">
                    {confirmPassword.length > 0 && (
                      <button className="editB1">
                        <img
                          onClick={handleToggleConfirmPassword}
                          src={showConfirmPassword ? eyeOff : eyeOn}
                          alt=""
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                {loading ? (
                  <button class="loginButton-2 rounded border-0 text-light fw-bold px-5 py-2 mt-3 align-items-center">
                    <span class="loading"></span>
                  </button>
                ) : (
                  <button
                    class="bColor rounded border-0 text-light fw-bold px-5 py-2 custom-mt user-select-none"
                    type="submit"
                  >
                    تایید
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </body>
  );
}
