import React, { useState } from "react";
import "../home.css";
import slice from "../../../../asset/homeAssest/Slice.svg";
import { Link } from "react-router-dom";
const RightNav = () => {
  const [open, setOpen] = useState(false);
  const [dash,setDash] = useState(true);
  const [mosh,setMosh] = useState(false);
  const [emp,setEmp] = useState(false);
  const [rep,setRep] = useState(false);
  const [haval,setHaval] = useState(false);
  const logout= () => {
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("userRole");
    localStorage.removeItem("user");
    window.location.href = '/login';
}
  return (
    <div className="h-100 c-bg">
      <div className="myHeight">
        <div className="cDiv">
          <img src={slice} className="img-res" alt="" />
        </div>
        <div className="c-bg cBLeft">
          <ul className="cUl px-0 d-flex flex-column">
            <li className="cLiTag py-2 align-items-center d-flex">
              <Link className="link" to="/">
                <span className="cIcon icon1 me-2 ms-3"></span>
                <button className="cButton">داشبورد</button>
              </Link>
            </li>
            <li className="cLiTag py-2 align-items-center d-flex">
              <Link className="link" to="/home/customer">
                <span className="cIcon icon3 me-2 ms-3"></span>
                <button className="cButton">مشتریان</button>
              </Link>
            </li>
            <li className="cLiTag py-2 align-items-center d-flex">
              <Link className="link" to="/home/employee">
                <span className="cIcon icon4 me-2 ms-3"></span>
                <button className="cButton">کارمندان</button>
              </Link>
            </li>
            <li className="cLiTag py-2 align-items-center d-flex">
              <Link className="link" to="/home/reports">
                <span className="cIcon icon6 me-2 ms-3"></span>
                <button className="cButton">گزارشات</button>
              </Link>
            </li>
            <li className="cLiTag py-2 align-items-center d-flex">
              <Link className="link" to="/home/havale">
                <span className="cIcon icon7 me-2 ms-3"></span>
                <button className="cButton">حواله</button>
              </Link>
            </li>
            <li
              class="cLiTag py-2 align-items-center d-flex position-relative"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <span class="cIcon userSettingIcon me-2 ms-3"></span>
              <button class="cButton">تنظیمات</button>
              <button
                class={`${
                  open ? "userDownArrow" : "userLeftArrow"
                } me-2 ms-3 ttt`}
              ></button>
            </li>
            {open && (
              <li class="liBg d-flex pt-1 pb-2 w-100">
                <Link className="link" to="/home/resetPass">
                  <span class="userResetPass cIcon me-2 ms-4"></span>
                  <button class="cButton">تغییر رمز عبور</button>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
      <li className="cLiTag py-2 align-items-center d-flex">
        <Link className="link" onClick={logout}>
          <span className="cIcon icon10 me-2 ms-3"></span>
          <button className="cButton">خروج</button>
        </Link>
      </li>
    </div>
  );
};
export default RightNav;
